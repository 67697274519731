export default {
    data() {
        return {
            lang: 'dk',
        }
    },
    methods: {
        setcookie: function (cookieName, cookieValue, nDays)
        {
            var d = new Date();
            if (nDays==null || nDays==0) nDays = 1;
            d.setTime(d.getTime() + 3600000*24*nDays);
            document.cookie = cookieName+"="+escape(cookieValue)+";expires="+d.toGMTString();
        },
        getCookie: function (cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
            }
            return "";
        },
    }
}
