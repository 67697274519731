<script>

import Category from "./Steps/Category.vue";
import Product from "./Steps/Product.vue";
import MultipleImages from "./Steps/MultipleImages.vue";
import MultipleImagesGallery from "./Steps/MultipleImagesGallery.vue";
import Attributes from "./Steps/Attributes.vue";
import AddonFrame from "./Steps/AddonFrame.vue";
import AddonPrint from "./Steps/AddonPrint.vue";
import AddonDigital from "./Steps/AddonDigital.vue";
import AddonClasphoto from "./Steps/AddonClasphoto.vue";

export default {
    components: {Category, Product, MultipleImages, MultipleImagesGallery, Attributes, AddonClasphoto, AddonDigital, AddonPrint, AddonFrame},
    props: ['app'],
    data: function () {
        return {

            steps: this,
            categoryProducts: {},
        }
    },
    methods: {
    },
}
</script>

<template>
    <div class="step-blocks">
        <section id="step1" class="step first border" v-if="this.app.basketStep > 0" >
            <div class="container sm">
                <div class="step-title">
                    <span class="count-item">1</span>
                    <h3>{{ trans("Vælg kategori") }}</h3>
                </div>
                <div class="row-holder">
                    <div class="col-4" v-for="(category, categoryId) in this.app.categories">
                        <Category :app="this.app" :steps="this.steps" :category="category" :categoryId="categoryId" />
                    </div>
                </div>
            </div>
        </section>
        <section id="step2" class="step second border" v-if="this.app.basketStep > 1">
            <div class="container sm">
                <div class="step-title">
                    <span class="count-item">2</span>
                    <h3>{{ trans("Vælg produkt") }}</h3>
                </div>
                <div class="row-holder">
                    <div class="col-4" v-for="product in this.categoryProducts">
                        <Product :app="this.app" :steps="this.steps" :product="product"/>
                    </div>
                </div>
            </div>
        </section>
        <section id="step3" class="step third border"  v-if="this.app.basketStep > 2">
            <div class="container sm">
                <div class="step-title">
                    <span class="count-item">3</span>
                    <h3>{{ trans("Vælg farve eller sort/hvid") }}</h3>
                </div>
                <Attributes :app="this.app" :attributes="this.app.products[this.app.prodId].attributes" :selectedProdId="this.app.prodId" />
                <div class="info-block">
                    <i class="icon-info"></i>
                    <div class="text">
                        <span class="info-text">{{ trans("Bemærk at billederne du ser her er råfiler. Billederne du modtager er yderligere forbedret og beskåret.") }}</span>
                    </div>
                </div>
            </div>

<!--            <div class="container sm" v-else>-->
<!--                <MultipleImages :app="this.app" :steps="this.steps" />-->
<!--            </div>-->
        </section>
        <section id="step4" class="step four border" v-if="this.app.basketStep > 3">
            <div class="container sm">
                <div class="step-title">
                    <span class="count-item">4</span>
                    <h3>{{ trans("Tilvalg") }}</h3>
                    <span class="subtitle">( vælg alle dem du vil )</span>
                </div>

<!--                todo: fix hardcode-->
                <div v-for="(addons, groupId) in this.app.products[this.app.prodId].addons">
                    <div v-if="groupId == 1">
                        <AddonDigital :app="this.app" :addons="addons" />
                    </div>

                    <div v-if="groupId == 2">
                        <AddonFrame :app="this.app" :addons="addons" />
                    </div>

                    <div v-if="groupId == 3">
                        <AddonPrint :app="this.app" :addons="addons" />
                    </div>

                    <div v-if="groupId == 4">
                        <AddonClasphoto :app="this.app" :addons="addons" />
                    </div>
                </div>

<!--                <MultipleImagesGallery :app="this.app" />-->


            </div>
        </section>
    </div>
</template>

<style scoped>

</style>
