<script>
export default {
    props: ['app'],
    data: function () {
        return {
        }
    },
    methods: {
    },
}
</script>

<template>
    <div class="container sm">
        <div class="total-price">
            <div class="total-text">
                <span class="total">{{ trans("Total") }}</span>
                <span class="price">{{ this.app.basket.total }},-</span>
            </div>
            <button type="button" class="btn primary" href="javascript:void(0)" @click="this.app.addToOrder()">{{ trans("Tilføj til kurv") }}</button>
        </div>
    </div>
</template>

<style scoped>

.total-price{
    justify-content: space-between;
}

</style>
