export default {
    data() {
        return {
            translations: {
                1 :{"dk":"Alt indrammet", "en":"Everything framed"},
                2 :{"dk":"Antal valgte filer", "en":"Number of selected files"},
                3 :{"dk":"Bemærk at billederne du ser her er råfiler. Billederne du modtager er yderligere forbedret og beskåret.", "en":"Notice that the pictures you are seeing on this page are raw, the final products will be cropped and improved further."},
                4 :{"dk":"Bestil mere til det samme billede", "en":"Order more for the same image"},
                5 :{"dk":"Billeder", "en":"Pictures"},
                6 :{"dk":"Billedet er til redigering", "en":"We are editing the picture"},
                7 :{"dk":"Digital fil", "en":"Digitale file"},
                8 :{"dk":"Vil du have filen med i høj opløsning?", "en":"Do you want the file in high resolution?"},
                9 :{"dk":"Har du bestilt på tilmeldingssedlen ligger det klar i din kurv.\\nGå blot ind og vælg billeder.\\nØnsker du at ændre bestilling fjerner du bestillingen og laver en ny.\\nEr der ingen bestilling i din kurv laver du blot en.Husk at færdiggøre din bestilling ved at gå til betaling, da uafsluttede ordre slettes ved deadline.", "en":"If you have ordered on the registration form, it is ready in your basket.\\nJust go in and select pictures.\\nIf you want to change the order, remove the order and make a new one.\\nIf there is no order in your basket, just make one. Remember to complete your order by going to payment as unfinished order is deleted by deadline."},
                10 :{"dk":"Du kan trygt bestille det alligevel, da du altid kan returnere det igen, hvis du ikke er tilfreds.", "en":"You can always return your classphoto if you are not satisfied with the final product."},
                11 :{"dk":"Du har nu opnået 1⁄2 pris på ekstra print af samme billede.", "en":"You have now obtained 1⁄2 price for extra print of the same image."},
                12 :{"dk":"Få portrættet i kvalitetsprint i mange forskellige størrelser", "en":"Get the portraits in quality prints."},
                14 :{"dk":"Fjern opret ny bestilling", "en":"Remove create new order"},
                15 :{"dk":"Fra", "en":"From"},
                16 :{"dk":"Normalpris", "en":"Normal price"},
                17 :{"dk":"Du sparer", "en":"You save"},
                18 :{"dk":"Yderligere rabat ved køb af endnu flere print.", "en":"Additional discount when buying even more prints."},
                19 :{"dk":"Gå til kurven", "en":"Continue to payment"},
                20 :{"dk":"Glimrende valg!", "en":"Excellent choice!"},
                21 :{"dk":"Gør-det-selv. Få ét eller flere portrætter som digitale filer", "en":"Do it yourself. Get one or more of the portraits as digital files"},
                22 :{"dk":"Herunder ser du alle billederne fra fotograferingen af dit barn, derfor vil der uden tvivl være nogle med lukkede øjne og sjove grimasser. Du skal blot vælge dine yndlingsbilleder, samt hvilke formater du ønsker dem leveret i, så sørger vi for resten. Vær opmærksom på at de billeder du ser her er uredigerede. De endelige billeder du vælger bliver rettet til i lys og skygge.", "en":"Below you can see all the pictures from the photosession, all you have to do is select your favorite photos and decide how you want them. We will take care of the rest."},
                23 :{"dk":"Indramning i sort ramme", "en":"Framing in a black frame"},
                24 :{"dk":"Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.", "en":"We are only able to ship frames to danish adresses, or you can choose to pick them up at our studio"},
                25 :{"dk":"Ja tak", "en":"Yes please"},
                26 :{"dk":"Klassebillede", "en":"Classphoto"},
                27 :{"dk":"Klassebillede 21 x 30 cm", "en":"Classphoto 21x30cm"},
                28 :{"dk":"Klassebillede print", "en":"Classphoto print"},
                29 :{"dk":"Opret ny bestilling", "en":"Create new order"},
                30 :{"dk":"Gå videre til bestilling", "en":"Continue with your order"},
                31 :{"dk":"Pakkeløsning", "en":"Packagesolution"},
                32 :{"dk":"Portrætter", "en":"Portraits"},
                33 :{"dk":"Print", "en":"Print"},
                34 :{"dk":"Print i ramme", "en":"Framed prints"},
                35 :{"dk":"Se eksempel", "en":"See example"},
                36 :{"dk":"Se mulighederne", "en":"See options"},
                37 :{"dk":"Start ny bestilling pået af de andre billeder", "en":"Start new order on the other pictures"},
                38 :{"dk":"Skal vi ramme det ind?", "en":"Should we frame it?"},
                39 :{"dk":"Du får dem sendt direkte hjem.", "en":"You get them sent straight home."},
                40 :{"dk":"Du skal ikke stå i kø i IKEA.", "en":"You do not have to stand in line at IKEA."},
                41 :{"dk":"Rammerne er med rigtigt glas og FSC mærket træ.", "en":"The frames are with real glass and FSC marked wood."},
                42 :{"dk":"Skal du have prints til halv pris?", "en":"Do you want prints 50% off?"},
                43 :{"dk":"Sort / hvid", "en":"Black/white"},
                44 :{"dk":"Spar 211 kr", "en":"Save 211kr"},
                45 :{"dk":"Spar 250 kr", "en":"save 250kr"},
                46 :{"dk":"Spar 50%", "en":"Save 50%"},
                47 :{"dk":"Spar besværet. Vi klarer både print & indramningen", "en":"Save yourself the trouble, we can do both the printing and the framing"},
                48 :{"dk":"Spar op til 50% ved at købe en af vores pakkeløsninger", "en":"Save up to 50% by choosing one of our packages"},
                49 :{"dk":"Tilføj", "en":"Add"},
                50 :{"dk":"Din bestilling på dit første billede er lagt i kurven. Tak for det!", "en":"Your order for your first photo has been placed in the basket. Thank you!"},
                51 :{"dk":"Tilføj til kurven", "en":"Add to cart"},
                52 :{"dk":"Tilføjet til din kurv", "en":"We have added this product to your cart"},
                53 :{"dk":"Tilvalg", "en":"Add-on"},
                54 :{"dk":"Total", "en":"Total"},
                56 :{"dk":"Træk dine billeder rundt, for at sammenligne dem side om side", "en":"Move your pictures around to compare them side to side"},
                57 :{"dk":"Vælg & bestil billeder", "en":"Select and order photos"},
                58 :{"dk":"Vælg billede", "en":"Select photo"},
                59 :{"dk":"Vælg et andet billede", "en":"Choose an other photo"},
                60 :{"dk":"Vælg farve eller sort/hvid", "en":"Choose color or black and white"},
                61 :{"dk":"Vælg herunder præcis hvordan du vil have portrættet.", "en":"Choose exactly how you would like the portrait"},
                62 :{"dk":"Vælg produkt", "en":"select product"},
                63 :{"dk":"Vælg kategori", "en":"Select category"},
                64 :{"dk":"vælger alle", "en":"select all"},
                65 :{"dk":"Velkommen!", "en":"Welcome!"},
                66 :{"dk":"Vil du se hvordan dine billeder ser ud i sort/hvid? Det slår du til i topbjælken", "en":"Do you want to see what your pictures look like in black/white, you can do this in the top of your browser window"},
                67 :{"dk":"Vis i sort/hvid", "en":"See pictures in black/white"},
                68 :{"dk":"Adresse", "en":"Adress"},
                69 :{"dk":"Afhent selv fra vores studie på Grusbakken 2, 2820 Gentofte", "en":"Pick up the photos from our studio Grusbakken 2k 2820 Gentofte"},
                70 :{"dk":"Bestilling", "en":"Order"},
                71 :{"dk":"Betalings metode", "en":"Payment method"},
                72 :{"dk":"Billede #", "en":"Picture #"},
                73 :{"dk":"By", "en":"City"},
                74 :{"dk":"Din kurv", "en":"Cart"},
                75 :{"dk":"Dine oplysninger", "en":"Your information"},
                76 :{"dk":"Du har kvalificeret dig til at få 50% rabat på ekstra print. Rabatten fratrækkes i kurven.", "en":"You have qualified for 50% discount on extra prints, the discount will be withdrawn in the cart."},
                77 :{"dk":"Du har opnået gratis levering til døren. Godt gået!", "en":"You have qualified for free delivery!"},
                78 :{"dk":"Du mangler kun at shoppe for 185,- mere for at opnå gratis levering til døren", "en":"You only need to shop for # amount more the get free delivery"},
                79 :{"dk":"E-mail", "en":"E-mail"},
                80 :{"dk":"ekskl. levering", "en":"Excl. delivery"},
                81 :{"dk":"Fakturering & leveringsoplysninger", "en":"Invoicing and delivery information"},
                82 :{"dk":"Farve", "en":"Color"},
                83 :{"dk":"Gå til betaling", "en":"Continue to payment"},
                84 :{"dk":"Gentag e-mail", "en":"Repeat email"},
                85 :{"dk":"Handelsbetingelser", "en":"Terms and conditions"},
                86 :{"dk":"Indrammede portrætter kan ikke leveres til barnets skole af hensyn til rammens sikkerhed", "en":"Framed portraits are not delivered to the school"},
                87 :{"dk":"Inkl. levering", "en":"Incl. delivery"},
                88 :{"dk":"Jeg har læst og accepterer Rosenfeldt", "en":"I have read and accept the terms and conditions"},
                89 :{"dk":"Levering", "en":"Delivery"},
                90 :{"dk":"Levering til døren", "en":"Delivery to your doorstep"},
                91 :{"dk":"Med stor digital fil", "en":"Incl. high resolution digital file"},
                92 :{"dk":"Navn (Hans Hansen)", "en":"Name (John Johnson)"},
                93 :{"dk":"Postnr. (4-cifre)", "en":"Postal code (4 digits)"},
                95 :{"dk":"Produkt", "en":"Product"},
                96 :{"dk":"Shop videre", "en":"Continue shopping"},
                97 :{"dk":"Telefonnummer (8-cifre)", "en":"Phonenumber (8 digits)"},
                98 :{"dk":"Tilbage til kurven", "en":"Return to basket"},
                100 :{"dk":"Total", "en":"Total"},
                101 :{"dk":"Type", "en":"Type"},
                102 :{"dk":"Vælg hvordan du ønsker at betale", "en":"Select payment method"},
                103 :{"dk":"Vælg hvordan du ønsker dit køb leveret", "en":"Select delivery method"},
                104 :{"dk":"Vil du have ekstra print med til 1/2 pris?", "en":"Do you want 50% of on extra prints?"},
                105 :{"dk":"(gratis ved køb over 499,-)", "en":"(free for purchases over 499,-)"},
                106 :{"dk":"Gå til kassen", "en":"Go to checkout"},
                108 :{"dk":"Levering til skole", "en":"Delivery to school"},
                109 :{"dk":"Tillykke, du har opnået gratis fragt lide til døren!", "en":"Congratulations, you have received free shipping suffer to the door!"},
                110 :{"dk":"You don't have a photo class", "en":"You don't have a photo class"},
                111 :{"dk":"Klassebillede og Fjollebillede", "en":"Class photo and Silly photo"},
                112 :{"dk":"Fjollebillede 21 x 30 cm", "en":"Silly picture 21 x 30 cm"},
                113 :{"dk":"Fjollebillede print", "en":"Silly picture print"},
                114 :{"dk":"Tilføj til kurv", "en":"Add to cart"},
                115 :{"dk":"Start ny bestilling på et af de andre billeder", "en":"Start a new order on one of the other images"},
                116 :{"dk":"Gruppebilleder", "en":"Group Photos"},
                117 :{"dk":"Tak for at tilmelde dit barn til skolefotografering du har tilmeldt", "en":"Thank you for signing up the following children to this years school photography session "},
                118 :{"dk":"Ønsker du at tilmelde flere børn", "en":"Do you wish to sign up another student"},
                119 :{"dk":"Fornavn", "en":"Firstname"},
                120 :{"dk":"Efternavn", "en":"Lastname"},
                121 :{"dk":"Telefon", "en":"Phone"},
                123 :{"dk":"Postnummer (4 cifre)", "en":"Postal code (4 digits)"},
                124 :{"dk":"Hej", "en":"Hi"},
                125 :{"dk":"Tusind tak for din bestilling af skolefotos, det er super sejt! Vi er mega glade for at have modtaget din bestilling, og den har fået dette nummer", "en":"A big thank you for your awesome school photos order! We're really excited to have received your order, and it's been assigned the number"},
                126 :{"dk":"Vores team er allerede i gang med at trylle og lave de fedeste billeder til dig. Vi lover at gøre dem helt fantastiske! Vi ved, at du er spændt på at få dem, og vi arbejder stenhårdt på at få dem klar til dig så hurtigt som muligt.", "en":"Our team is already hard at work, creating and finalising the coolest photos just for you. We promise to make them absolutely fantastic! We know you're eager to get your hands on them, and we're working our socks off to have them ready for you as soon as possible."},
                127 :{"dk":"Normalt tager det omkring 3-4 uger at få alle detaljerne på plads, for vi tager os tid til at gøre det rigtigt. Men tit går det faktisk hurtigere end det!", "en":"Usually, it takes around 3 weeks to get all the details in place, because we take the time to get it just right. But often, things actually move even quicker than that!"},
                128 :{"dk":"Hvis du har nogen spørgsmål eller bare gerne vil sludre om din ordre, så tøv ikke med at skrive til os på <a href=\'mailo:rosenfeldt@rosenfeldt.nu\'>rosenfeldt@rosenfeldt.nu</a> eller give os et kald på", "en":"If you have any questions or just want to chat about your order, please don't hesitate to drop us a note at <a href=\'mailo:rosenfeldt@rosenfeldt.nu\'>rosenfeldt@rosenfeldt.nu</a> or give us a call at"},
                129 :{"dk":"Vi er her for at hjælpe dig.", "en":"We're here to help you out."},
                130 :{"dk":"Kan du holde på en hemmelighed? Vi fotografere også sportsklubber og erhverv, kig på <a href=\'rosenfeldt.nu\'>rosenfeldt.nu</a> men husk det er en hemmelighed!", "en":""},
                131 :{"dk":"Bedste hilsner,", "en":"Best regards,"},
                132 :{"dk":"Fotograferne fra Rosenfeldt", "en":"The photographers from Rosenfeldt"},
                133 :{"dk":"Vi glæder os mega meget til at vise dig resultatet!", "en":"Thanks again for choosing us to capture your awesome moments. We can't wait to show you the results!"},
                134 :{"dk":"Indsæt token", "en":"Insert token"},
                135 :{"dk":"Jeg har allerede betalt fragt på en anden order (Indsæt token)", "en":"I have already paid shipping on another order (Insert token)"},
                136 :{"dk":"Jeg har allerede betalt fragt på en anden bestilling", "en":"Delivery with sibling's order"},
                138 :{"dk":"Downloader (hvis du kun har bestilt digitale filer)", "en":"Downloading (if you only ordered digital files)"},
                139 :{"dk":"Pakker", "en":"Package solutions"},
                140 :{"dk":"Din familie og venner kan også have glæde af et billede.Få mere for dine penge ved, at vælge en af vores pakkeløsninger.", "en":"Save up to 50% by buying one of our package solutions"},
                141 :{"dk":"Lille pakke", "en":"Small package"},
                142 :{"dk":"Stor pakke", "en":"Large package"},
                143 :{"dk":"Mellem pakke", "en":"Middle pack"},
                145 :{"dk":"Digital billedefil i høj opløsning", "en":"Digital image file in high resolution"},
                146 :{"dk":"A5 / 16 byttebilleder", "en":"A5 / 16 exchange photos"},
                147 :{"dk":"16 små byttebilleder printet på 1 A5 ark.", "en":"16 small photos printed on one A5. Good for exchange between students"},
                148 :{"dk":"A4 / 24 byttebilleder", "en":"A5 / 24 exchange photos"},
                149 :{"dk":"24 små byttebilleder printet på 1 A4 ark.", "en":"24 small photos printed on one A4. Good for exchange between students"},
                153 :{"dk":"<p>1 stk digital billedefil i høj opløsning til print</p>", "en":"<p>Digital image file in high resolution for printing</p>"},
                154 :{"dk":"skole", "en":"school"},
                155 :{"dk":"studie", "en":"studio"},
                156 :{"dk":"hjem", "en":"home"},
                157 :{"dk":"Farve", "en":"Colored"},
                158 :{"dk":"Sort/Hvid", "en":"Black/White"},
                159 :{"dk":"Klassebillede A5", "en":"Class picture A5"},
                160 :{"dk":"Klassebillede A4", "en":"Class picture A4"},
                161 :{"dk":"Klassebillede A3", "en":"Class picture A3"},
                162 :{"dk":"Digital Fil", "en":"Digital file"},
                165 :{"dk":"Få portrættet printet på kvalitetsprint i mange forskellige størrelser", "en":"Get the portraits in quality prints."},
                166 :{"dk":"Indrammede printede billeder", "en":"Framed prints"},
                167 :{"dk":"Spar besværet. Vi klarer både print og indramning", "en":"Save yourself the trouble, we can do both the printing and the framing"},
                168 :{"dk":"Indramning A4", "en":"Framing A4 (addon)"},
                169 :{"dk":"Indramning A5", "en":"Framing A5 (addon)"},
                170 :{"dk":"Klasse", "en":"Classes"},
                171 :{"dk":"Elevens Fornavn", "en":"Student First Name"},
                172 :{"dk":"Elevens Efternavn", "en":"Student Last Name"},
                173 :{"dk":"Forældre Fornavn", "en":"Parent First Name"},
                174 :{"dk":"Forældre Efternavn", "en":"Parent Last Name"},
                176 :{"dk":"Forældre 1 email", "en":"Parent 1 email"},
                177 :{"dk":"Forældre 1 telefon", "en":"Parent 1 phone"},
                178 :{"dk":"Forældre 2 email", "en":"Parent 2 email"},
                179 :{"dk":"Forældre 2 telefon", "en":"Parent 2 phone"},
                180 :{"dk":"Indsend", "en":"Submit"},
                182 :{"dk":"Fjollebillede A5", "en":"Silly Photo A5"},
                183 :{"dk":"Fjollebillede A4", "en":"Silly Photo A4"},
                184 :{"dk":"<ul><li>2 Portrætter 180x240</li><li>4 Portrætter 130x180</li><li>2 Klassebilleder 180x270</li><li>1 Lavt digitalt portræt 148x210</li><li>Retouchering</li></ul>", "en":"<ul><li>2 Portrets 180x240</li><li>4 Portrets 130x180</li><li>2 Classpictures 180x270</li><li>1 Low digital portret 148x210</li><li>Retouching</li></ul>"},
                185 :{"dk":"<ul><li>2 Portrætter 18x24 cm</li><li>4 Portrætter 13x18 cm</li><li>2 Klassebilleder 18x27 cm</li><li>1 Lavopløsnings digitalt portræt (kun til webbrug)</li></ul>","en":"<ul><li>2 Portraits 18x24cm</li><li>4 Portraits 13x18cm</li><li>2 Classpictures 18x27cm</li><li>1 Low digital portrait (only for web use)</li></ul>", "en":"<ul><li>2 Portraits 18x24cm</li><li>4 Portraits 13x18cm</li><li>2 Classpictures 18x27cm</li><li>1 Low digital portrait (only for web use)</li></ul>"},
                186 :{"dk":"<ul><li>1 Portræt 18x24 cm</li><li>4 Portrætter 13x18 cm</li><li>2 Portrætter 9x12 cm + 4 Portrætter 6x9 cm + 4 Portrætter 3,5x4,5 cm</li><li>1 Klassebillede 18x27 cm</li><li>1 Lavopløsnings digitalt portræt (kun til webbrug)</li></ul>", "en":"<ul><li>1 Portrait 18x24cm</li><li>4 Portraits 13x18cm</li><li>2 Portraits 9x12cm + 4 Portraits 6x9cm + 4 Portraits 3.5x4.5cm</li><li>1 Classpictures 18x27cm</li><li>1 Low digital portrait (only for web use)</li></ul>"},
                187 :{"dk":"<ul><li>1 Portræt 18x24 cm</li><li>1 Portræt 13x18 cm + 2 Portrætter 9x12 cm</li><li>1 Klassebillede 18x27 cm</li><li>1 Lavopløsnings digitalt portræt (kun til webbrug)</li></ul>", "en":"<ul><li>1 Portrait 18x24cm</li><li>1 Portrait 13x18cm + 2 Portraits 9x12cm</li><li>1 Classpicture 18x27cm</li><li>1 Low digital portrait (only for web use)</li></ul>"},
                188 :{"dk":"<ul><li>1 Portræt 13x18 cm + 2 Portrætter 9x12 cm</li><li>1 Klassebillede 180x270 cm</li><li>1 Lavopløsnings digitalt portræt (kun til webbrug)</li></ul>", "en":"<ul><li>1 Portrait 13x18cm + 2 Portraits 9x12cm</li><li>1 Classpicture 180x270</li><li>1 Low digital portrait (only for web use)</li></ul>"},
                189 :{"dk":"<ul><li>1 Klassebillede 18x27 cm</li><li>1 Farvet digitalt portræt (kan bruges til print)</li><li>1 Sort/hvid digitalt portræt (kan bruges til print)</li></ul>", "en":"<ul><li>1 Classpicture 18x27cm</li><li>1 Colored digital portrait (can be used for print)</li><li>1 B/W digital portrait (can be used for print)</li></ul>"},
                190 :{"dk":"<ul><li>1 Portræt 13x18 cm + 1 Klassebillede 13x18 cm</li><li>1 Lavopløsnings digitalt portræt (kun til webbrug)</li></ul>", "en":"<ul><li>1 Portrait 13x18cm + 1 Classpicture 13x18cm</li><li>1 Low digital portrait (only for web use)</li></ul>"},
                191 :{"dk":"<ul><li>1 Digitalt portræt i høj opløsning, kan bruges til print.</li></ul>", "en":"<ul><li>1 High digital portrait file, can be used for print</li></ul>"},
                192 :{"dk":"<ul><li>1 Portræt 18x24 cm</li></ul>", "en":"<ul><li>1 Portrait 18x24cm</li></ul>"},
                193 :{"dk":"<ul><li>2 Portrætter 13x18 cm</li></ul>", "en":"<ul><li>2 Portraits 13x18cm</li></ul>"},
                194 :{"dk":"<ul><li>1 Portræt 13x18 cm</li><li>2 Portrætter 9x12 cm</li></ul>", "en":"<ul><li>1 Portrait 13x18cm</li><li>2 Portraits 9x12cm</li></ul>"},
                195 :{"dk":"<ul><li>4 Portrætter 9x12 cm</li></ul>", "en":"<ul><li>4 Portraits 9x12cm</li></ul>"},
                196 :{"dk":"<ul><li>2 Portrætter 9x12 cm</li><li>4 Portrætter 6x9 cm</li><li>4 Portrætter 3,5x4,5 cm</li></ul>", "en":"<ul><li>2 Portraits 9x12cm</li><li>4 Portraits 6x9cm</li><li>4 Portraits 3.5x4.5cm</li></ul>"},
                197 :{"dk":"<ul><li>2 Portrætter 9x12 cm</li><li>15 Portrætter 3,5x4,5 cm</li></ul>", "en":"<ul><li>2 Portraits 9x12cm</li><li>15 Portraits 3.5x4.5cm</li></ul>"},
                198 :{"dk":"<ul><li>1 Portræt 30x40</li><li>1 Ramme 30x40</li></ul>", "en":"<ul><li>1 Portrait 30x40</li><li>1 Frame 30x40</li></ul>"},
                199 :{"dk":"<ul><li>1 Portræt 40x50</li><li>1 Ramme 40x50</li></ul>", "en":"<ul><li>1 Portrait 40x50</li><li>1 Frame 40x50</li></ul>"},
                200 :{"dk":"<ul><li>1 Portræt 50x70</li><li>1 Ramme 50x70</li></ul>", "en":"<ul><li>1 Portrait 50x70</li><li>1 Frame 50x70</li></ul>"},
                201 :{"dk":"<p>Gruppebillede 21x30</p>", "en":"<p>Group photo 21x30</p>"},
                202 :{"dk":"Indramning 21x30", "en":"Frame 21x30 (addon)"},
                203 :{"dk":"Indramning 13x18", "en":"Frame 13х18 (addon)"},
                204 :{"dk":"Ramme til klassebillede 21x30", "en":"Frame for classphoto 21x30 (addon)"},
                205 :{"dk":"1 Portræt 18x24 cm", "en":"1 Portrait 18x24cm (addon)"},
                206 :{"dk":"2 Portrætter 13x18 cm", "en":"2 Portraits 13x18cm (addon)"},
                207 :{"dk":"1 Portræt 13x18 cm + 2 Portrætter 9x12 cm", "en":"1 Portrait 13x18cm + 2 Portraits 9x12cm (addon)"},
                208 :{"dk":"4 Portrætter 9x12 cm", "en":"4 Portraits 9x12cm (addon)"},
                209 :{"dk":"2 Portrætter 9x12 cm 4 Portrætter 6x9 cm 4 Portrætter 3,5x4,5 cm", "en":"2 Portraits 9x12cm + 4 Portraits 6x9cm + 4 Portraits 3.5x4.5cm (addon)"},
                210 :{"dk":"2 Portrætter 9x12 cm 15 Portrætter 3,5x4,5 cm", "en":"2 Portraits 9x12cm + 15 Portraits 3.5x4.5cm (addon)"},
                211 :{"dk":"Ekstra klassebillede", "en":"Extra classphoto 21x30cm (addon)"},
                212 :{"dk":"Digital fil i høj opløsning (addon)", "en":"Digital File high resolution (addon)"},
                213 :{"dk":"To Share", "en":"To Share"},
                214 :{"dk":"Standard Pakken ( Den mest købte )", "en":"Standart package"},
                215 :{"dk":"Mini Pakken", "en":"Mini Package"},
                216 :{"dk":"Micro Pakken", "en":"Micro Package"},
                217 :{"dk":"Digital Pakken", "en":"Digital Package"},
                218 :{"dk":"Budget Pakke", "en":"Budget Package"},
                219 :{"dk":"Digital Fil", "en":"Digital File"},
                220 :{"dk":"1 18x24 cm", "en":"1 Portrait 18x24"},
                221 :{"dk":"2 stk 13x18 cm", "en":"2 Portraits 13x18"},
                222 :{"dk":"1 stk 13x18 cm + 2 stk 9x12 cm", "en":"1 Portrait 13x18 + 2 Portraits 9x12"},
                223 :{"dk":"4 stk 9x12 cm", "en":"4 Portraits 9x12"},
                224 :{"dk":"2 stk 9x12 cm + 4 stk 6x9 cm + 4 stk 3.5x4.5 cm", "en":"2 Portraits 9x12 + 4 Portraits 6x9 + 4 Portraits 3.5x4.5"},
                225 :{"dk":"2 stk 9x12 + 15 stk 3.5x4.5", "en":"2 Portraits 9x12 + 15 Portraits 3.5x4.5"},
                226 :{"dk":"Portræt printet i 30x40 indrammet", "en":"Framed portrait 30x40"},
                227 :{"dk":"Portræt printet i 40x50 indrammet", "en":"Framed portrait 40x50"},
                228 :{"dk":"Portræt printet i 50x70 indrammet", "en":"Framed portrait 50x70"},
                229 :{"dk":"Klassebillede 21x30", "en":"Classphoto 21x30"},
                230 :{"dk":"Indramning 21x30 ", "en":"Frame 21x30 (addon)"},
                231 :{"dk":"Indramning 13x18 ", "en":"Frame 13х18 (addon)"},
                232 :{"dk":"Ramme til klassebillede 21x30", "en":"Frame for classphoto 21x30 (addon)"},
                233 :{"dk":"1 Portræt 18x24 cm", "en":"1 Portrait 18x24cm (addon)"},
                234 :{"dk":"2 Portrætter 13x18 cm", "en":"2 Portraits 13x18cm (addon)"},
                235 :{"dk":"1 Portræt 13x18 cm + 2 Portrætter 9x12 cm", "en":"1 Portrait 13x18cm + 2 Portraits 9x12cm (addon)"},
                236 :{"dk":"4 Portrætter 9x12 cm", "en":"4 Portraits 9x12cm (addon)"},
                237 :{"dk":"2 Portrætter 9x12 cm 4 Portrætter 6x9 cm 4 Portrætter 3,5x4,5 cm", "en":"2 Portraits 9x12cm + 4 Portraits 6x9cm + 4 Portraits 3.5x4.5cm (addon)"},
                238 :{"dk":"2 Portrætter 9x12 cm 15 Portrætter 3,5x4,5 cm", "en":"2 Portraits 9x12cm + 15 Portraits 3.5x4.5cm (addon)"},
                239 :{"dk":"Ekstra klassebillede", "en":"Extra classphoto 21x30cm (addon)"},
                240 :{"dk":"Digital fil i høj opløsning (addon)", "en":"Digital File high resolution (addon)"},
                241 :{"dk":"Er det muligt at få trykt sine billeder i andre størrelser end dem i tilbyder her på siden?", "en":"Is it possible to have printed photos in other sizes than the ones you offer on this page?"},
                242 :{"dk":"Det er det! Skriv til rosenfeldt@rosenfeldt.nu og vi finder ud af det.", "en":"That is it! Write to rosenfeldt@rosenfeldt.nu and we'll find out."},
                243 :{"dk":"Du kan oprette en ny ordre til højre på siden", "en":"Order is closed"},
                244 :{"dk":"Opret ny ordre", "en":"Create a new order"},
                245 :{"dk":"Fjollebillede", "en":"Sillyphoto"},
            }
        }
    },
    methods: {
        trans: function (phrase) {
            if (!!phrase) {
                this.lang = (this.getCookie('language')) ? this.getCookie('language') : 'dk';

                for (let [key, trans] of Object.entries(this.translations)) {
                    for(let [lang, value] of Object.entries(trans)) {
                        if (value == phrase) {
                            var translatedPhrase = this.translations[key][this.lang];
                            return translatedPhrase;
                        }
                    }
                }
            }

            return phrase;
        },
    }
}
