<script>
import AddonItem from "./AddonItem.vue";

export default {
    components: {AddonItem},
    props: ['app', 'addons'],
    data: function () {
        return {

        }
    },
    methods: {

    },
}
</script>
<template>
    <div class="card-block b-shadow card-block-hover">
        <div class="title-holder">
            <strong class="sort">{{ trans("Vil du have filen med i høj opløsning?") }}</strong>
            <!--                        <p>Lige til at lorem ipsum dolor sit amet consectetur.</p>-->
        </div>
        <div class="total-price">
            <span class="price">39,-</span>
        </div>
        <div class="total-price">
            <span class="value"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Normalpris") }}</span></span></span>
            <span class="price">250,-</span>
            <span class="value"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Du sparer") }}</span></span></span>
            <span class="price">211,-</span>
            <a href="javascript:void(0)" class="btn primary">{{ trans("Spar 211 kr") }}</a>
        </div>
<!--        <form class="checkbox-holder check" action="#">-->
<!--            <div class="toogle-holder">-->
<!--                <label class="switch">-->
<!--                    <input type="checkbox" :disabled="true">-->
<!--                    <span class="element"></span>-->
<!--                </label>-->
<!--            </div>-->
<!--        </form>-->
        <div class="product_item_wrapper" v-for="addon in this.addons">
            <AddonItem :app="this.app" :addon="addon" />
        </div>
    </div>
</template>

<style scoped>
 .product_item_wrapper{
     margin-top: 40px;
 }
 .product_item{
     padding: 0;
 }
</style>
