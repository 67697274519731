<script>

import GroupPhoto from "./GroupPhoto.vue";

import Cart from './Cart.vue'
import PhotoList from "./PhotoList.vue";
import Steps from "./Steps.vue";
import SuccessCart from "./SuccessCart.vue";
import Checkout from "./Checkout.vue";
import Header from "./Header.vue"
import Introduction from "./Introduction.vue";
import Gallery from "./Gallery.vue";
import ModalGallery from "./ModalGallery.vue";
import AddToOrder from "./AddToOrder.vue";

export default {
    components: {
        AddToOrder,
        GroupPhoto,
        Header,
        Cart,
        Introduction,
        Checkout,
        PhotoList,
        Steps,
        SuccessCart,
        Gallery,
        ModalGallery,
    },
    data: function () {
        return {
            app: this,
            panelsDomain: 'https://' + window.panelsDomain + '/',
            currentDomain: 'https://'+ window.location.hostname,
            startPage: 'https://'+ window.location.hostname + '/customer/' + this.$route.params.customer + '/order/' + this.$route.params.order + '/token/' + this.$route.params.token,
            id: '',
            token: '',
            order_id: '',
            photos: {},
            products: {},

            isGreyImg: false,
            activeTab: 0,

            isGallery: false,
            galleryPhoto: {},
            isMultipleGallery: false,
            multipleGalleryImg: '',

            customerName: '',
            className: '',
            customerId: '',

            addresses: null,
            currentAdderess: null,

            relatives: null,
            parent: null,

            deadlineTime: '',
            isTimer: false,
            timer: null,
            printDeadlineTime: '',
            deliverytypes: {},
            free_shipping: false,
            cart: {},
            categories: {},

            basket: {
                products: {},
                image: {},
                total: 0,
            },
            basketStep: 0,
            cartQnt: 0,
            prodId: null,

            paymentsystems: {},

            successToCart: false,
            notOrder: false,
            groupPhotosToFix: null,
            groupPhotoAttribute: null,
            groupPhotoAttributeValue: null,
            premadeCategoryId: null,
            premadeProductId: null,
            groupPhotoProducts: [],

            bookingUrl: '',
            companyName: '',
            companyEmail: '',
            mainDomain: '',
            officialComapnyName: '',
            companyPhone: '',
            companyAddress: '',
            companyCity: '',
            companyId: '',
            logo: '',
            logoMobile: '',
        }
    },
    computed: {
        currentUrl: function () {
            return window.location.href
        },
    },
    mounted: function () {
        this.id = this.$route.params.customer
        this.order_id = this.$route.params.order
        this.token = this.$route.params.token
        this.groupPhotosToFix = groupPhotosToFix
        this.groupPhotoProducts = this.groupPhotosToFix.split(',')
        this.groupPhotoAttribute = groupPhotoAttribute
        this.groupPhotoAttributeValue = groupPhotoAttributeValue

        this.bookingUrl = bookingUrl
        this.companyName = companyName
        this.companyEmail = companyEmail
        this.mainDomain = mainDomain
        this.officialComapnyName = officialComapnyName
        this.companyPhone = companyPhone
        this.companyAddress = companyAddress
        this.companyCity = companyCity
        this.companyId = companyId
        this.logo = logo
        this.logoMobile = logoMobile

        this.getData();
        this.startTimer();
    },
    watch: {
        'basket.products': {
            handler: function (newProdcuts, oldProdcucts) {
                this.updateBaketTotal()
            },
            deep: true
        },
        basketStep: function () {
            if (this.basketStep < 1) {
                this.resetBasket()
            }
            if (this.basketStep < 3) {
                this.resetBasketProducts()
            }
        },
        cartQnt: function () {
            $('.count-item-cart').animate({
                    width: '44px',
                    height: '44px',
                },
                1000,
                function () {
                    $('.count-item-cart').animate({
                        width: '22px',
                        height: '22px',
                    }, 1000);
                }
            );
        },
    },
    methods: {
        addToBasket: function(product, qty = 1, selectedAttributes = []) {

            let basketProduct =  {
                id: product.id,
                price: product.price,
                qty: qty,
                selectedAttributes: selectedAttributes,
            }

            this.basket.products[product.id] = basketProduct
        },
        updateBasketQty: function (productId, action, qty = 1, maxquantity = null) {

            if (!this.basket.products[productId]) {
                return
            }

            if (action == 'plus') {
                if (maxquantity && maxquantity <= this.basket.products[productId].qty) {
                    return
                }
                this.basket.products[productId].qty += qty

            } else if (action == 'minus' && this.basket.products[productId].qty > 0) {
                this.basket.products[productId].qty -= qty

                if (this.basket.products[productId].qty <= 0) {
                    delete this.basket.products[productId]
                }
            }

            if (this.basket.products[productId] && this.basket.products[productId].qty) {
                return this.basket.products[productId].qty
            } else {
                return 0
            }

        },
        updateBaketTotal: function () {
            if (this.prodId) {
                this.basket.total = 0

                for (let [id, product] of Object.entries(this.basket.products)) {

                    if (product.qty == 0) {
                        delete this.basket.products[id]
                    }

                    this.basket.total += product.qty * product.price
                }
            } else {
                this.basket.total = 0
            }
        },
        getData: function () {
            var url = this.panelsDomain + 'api/v1/webshop',
                data = new FormData();

            data.set('action', 'getData');
            data.set('order', this.order_id);
            data.set('id', this.id);
            data.set('token', this.token);
            data.set('lang', this.lang);

            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                if (!response?.data?.order) {
                    this.notOrder = true;
                    return false;
                }

                this.notOrder = false;
                this.photos = Object.assign({},response.data.photos);
                this.categories = response.data.categories;
                this.products = response.data.products

                this.customerId = response.data.customer.customer_id;
                this.firstname = response.data.customer.firstname
                this.lastname = response.data.customer.lastname
                this.customerName = response.data.customer.firstname + ' ' + response.data.customer.lastname
                this.className = response.data.customer.class;

                this.addresses = response.data.customer.addresses
                this.currentAdderess = this.getCurrentAdderess(response.data.customer.addresses)

                this.relatives = response.data.customer.relatives

                this.deadlineTime = new Date(response.data.order.selection_deadline).getTime();

                this.cart = response.data.order;
                this.cartQnt = response.data.order.products.length

                this.premadeProductId = this.getPremadeProductId(this.cart.products)
                this.premadeCategoryId = this.getPremadeCategoryId(this.products, this.premadeProductId)

                this.deliverytypes = response.data.order.deliverytypes

                this.paymentsystems = response.data.order.paymentsystems

            }).catch(function (error) {
                this.notOrder = true;
                console.log(error);
            })
        },
        getPremadeProductId: function (products) {
            for (let [key, product] of Object.entries(products)) {
                if (!product.img) {
                    //todo: fix hardcode
                    if (this.products[product.id].categories[0] == 5) {
                        return
                    }
                    return product.id
                }
            }
        },
        getPremadeCategoryId: function (products, premadeProductId) {
            for (let [key, product] of Object.entries(products)) {
                if (product.id == premadeProductId) {
                    return product.categories[0]
                }
            }
        },
        getCurrentAdderess: function (addresses) {
            let  currentAdderess = {
                'address': (!!addresses[0]) ? addresses[0].address : '',
                'zipcode': (!!addresses[0]) ? addresses[0].zipcode : '',
                'city': (!!addresses[0]) ? addresses[0].city : '',
            }

            return currentAdderess
        },
        checkProductCategory: function (product, categoryId) {
            for (let [i, category] of Object.entries(product.categories)) {
                if (category == categoryId) {
                    return true
                }
            }

            return false
        },
        checkSillyPhoto: function (product, className) {
            //todo: fix hardcode (if it will be needed in future)
            if (product.name.includes('Silly')) {
                if (className.includes(9) || className.includes(10) || className.includes(11) || className.includes(12) || className.includes('CMK')) {
                    return true
                } else {
                    return false
                }
            }

            return true
        },
        addToOrder: function () {
            var url = this.panelsDomain + 'api/v1/webshop';

            //todo: fix hardcode (figureout why the color attribute might missed and fix it to avoid using of this hardcoded function) (tasks #253, #271)
            this.basket = this.fixColorForGroupPhotos(this.basket)
            var dataObj = {
                'action': 'addToOrder',
                'order': this.order_id,
                'id': this.id,
                'token': this.token,
                'data': this.basket,
            };

            var data = objectToFormData(dataObj);
            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                this.successToCart = true
                this.getData();

            }).catch(function (error) {
                console.log(error);
            })

        },
        //todo: figureout why the color attribute might missed and fix it to avoid using of this hardcoded function (tasks #253, #271)
        fixColorForGroupPhotos: function (basket) {

            let groupProducts = this.groupPhotosToFix.split(',')

            let selectedAttributes = {
                [this.groupPhotoAttribute] : [this.groupPhotoAttributeValue]
            }
            for (let [key, product] of Object.entries(basket['products'])) {
                if (groupProducts.includes(product.id)) {
                    if (!!basket['products'][product.id]['selectedAttributes'][1]) {
                        if (!basket['products'][product.id]['selectedAttributes'][1][0]) {
                            basket['products'][product.id]['selectedAttributes'] = selectedAttributes
                        }
                    }
                }
            }

            return basket
        },
        delFromCart: function (product) {
            var url = this.panelsDomain + 'api/v1/webshop',
                data = new FormData();

            data.set('action', 'delFromOrder');
            data.set('order_id', this.order_id);
            data.set('id', this.id);
            data.set('token', this.token);
            data.set('product_id', product.id);

            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                this.successToCart = false;
                this.getData();

            }).catch(function (error) {
                console.log(error);
            })
        },
        startTimer() {
            this.timer = setInterval(() => {
                var time = new Date().getTime();
                var timerQuantity = this.deadlineTime - time;
                var time12Hours = new Date();
                time12Hours.setHours(time12Hours.getHours() + 12);

                var hours = ("0" + Math.floor((timerQuantity % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
                var minutes = ("0" + Math.floor((timerQuantity % (1000 * 60 * 60)) / (1000 * 60))).slice(-2);
                var seconds = ("0" + Math.floor((timerQuantity % (1000 * 60)) / 1000)).slice(-2);

                if (this.deadlineTime <= time12Hours.getTime() && timerQuantity > 0) {
                    this.printDeadlineTime = hours + ":" + minutes + ":" + seconds;
                    this.isTimer = true;
                } else {
                    if (timerQuantity < 0) {
                        clearTimeout(this.timer)
                    }
                    this.isTimer = false;
                }

            }, 1000)
        },
        resetBasket: function () {
            this.basket = {
                products: {},
                image: {},
                total: 0,
            }
            this.prodId = null
        },
        resetBasketProducts: function () {
            this.basket.products = {}
            this.prodId = null
        },
        setActiveTab: function (tabId) {
            this.resetBasket()
            if (tabId === 0) {
                this.activeTab = 0
            } else {
                this.activeTab = tabId
                this.prodId = tabId
                this.addToBasket(this.products[tabId], 1, [])
            }
        },
        createOrder: function () {
            var url = this.panelsDomain + 'api/v1/webshop',
                data = new FormData();

            data.set('action', 'createOrder');
            data.set('id', this.id);
            data.set('token', this.token);
            data.set('order', this.order_id);

            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                if (!response.data.order) {
                    this.notOrder = true;
                    return false;
                }
                return window.location.href = 'https://' + window.location.hostname + '/customer/' + this.id + '/order/' + response.data.order + '/token/' + this.token;
            }).catch(function (error) {
                console.log(error);
            })
        },
    }
}
</script>

<template>
    <div v-if="this.notOrder">
        <Header  :app="app" />
        <div id="main">
            <section class="title-section border" >
                <div class="container sm">
                    <h1>{{ trans("Order is closed") }}</h1>
                    <button type="button" class="btn primary" href="javascript:void(0)" @click="this.createOrder()">{{ trans("Create a new order") }}</button>
                </div>
            </section>
        </div>
    </div>
    <div v-else-if="this.$route.params.cart">
        <Cart :app="app" />
    </div>
    <div v-else-if="this.$route.params.checkout">
        <Checkout :app="app" />
    </div>
    <div v-else>

        <div id="cartPopup">
            <div id="wrapper">
                <Cart :app="app" />
            </div>
        </div>

        <div>
            <Header  :app="app" />
            <div id="main">
                <div class="timer container lg" v-if="isTimer">
                    <div class="text">
                        <p>{{ trans("Bestil inden deadline og få billederne leveret på skolen") }} <span>{{ printDeadlineTime }}</span></p>
                    </div>
                </div>
                <!-- photolist start -->
                <div v-if="this.basketStep == 0">
                    <Introduction :app="app"/>
                    <div class="photo-section">
                        <div class="container">
                            <div class="sort-links">
                                <a :class="{'active': this.activeTab == 0 }" @click="this.setActiveTab(0)" href="javascript:void(0)">{{ trans("Portrætter") }}</a>
                                <span  v-for="product in this.products">
<!--                                    todo: fix hardcode-->
                                    <span v-if="this.checkProductCategory(product, 5) === true && this.checkSillyPhoto(product, this.className)">
                                        <a :class="{'active': this.activeTab == product.id}" @click="this.setActiveTab(product.id)" href="javascript:void(0)">{{ trans(product.name) }}</a>
                                    </span>
                                </span>
                            </div>
                            <div class="arrows">
                                <span class="scrool-dots">...</span>
                                <span class="scrool-arrow">></span>
                            </div>
                            <div class="reset-flow"></div>
                            <!-- list photo start -->
                            <div class="gallery-holder gallery-photos" :class="{'active': this.activeTab == 0}">
                                <PhotoList :photos="photos" :isGreyImg="isGreyImg" :app="app" />
                            </div>
                            <!-- list photo end -->
                            <!-- Group photo start -->
                            <span  v-for="product in this.products">
<!--                                todo: fix hardcode-->
                                <span v-if="this.checkProductCategory(product, 5) === true">
                                    <div class="classe" :class="{'active': this.activeTab == product.id}">
                                        <GroupPhoto :app="app" :product="product" />
                                    </div>
                                </span>
                            </span>
                            <!-- Group photo end -->
                        </div>
                        <!-- modal gallery start -->
                        <Gallery  :app="app" />
                        <!-- modal gallery end -->
                    </div>
                </div>
                <!-- photolist end -->
                <!-- productSet start -->
                <div v-else>
                    <section class="intro-photo">
                        <div class="container sm">
                            <div class="row-holder">
                                <div class="col-6">
                                    <div class="chosen-photo b-shadow">
                                        <img class="not-retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + this.basket.image.img" alt="image description">
                                        <img class="retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + this.basket.image.img" alt="image description">
                                        <!-- fixme
                                                                <img class="retina" src="/images/photos@2x.jpg" alt="image description">
                                        -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="title-block">
                                        <h1>{{ trans("Glimrende valg!") }}</h1>
                                        <div class="text">
                                            <p>{{ trans("Vælg herunder præcis hvordan du vil have portrættet.") }}</p>
                                        </div>
                                        <a :href="this.startPage" class="btn outline">{{ trans("Vælg et andet billede") }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Steps :app="app" />
                    <div class="summary" v-if="this.app.basket.total > 0 && this.basketStep > 3">
                        <AddToOrder :app="app" />
                    </div>
                </div>
                <!-- success cart starts -->
                <SuccessCart :app="app"/>
                <!-- success cart end -->
                <!-- modal gallery start -->
                <ModalGallery  :app="app" />
                <!-- modal gallery end -->
            </div>
            <!-- productSet end -->
        </div>
    </div>
</template>

<style scoped>
    .arrows{
        display: none;
    }
@media (max-width: 1247px) {
    .arrows{
        display: flex;
        margin-top: -20px;
        float: right;
    }
    .sort-links{
        margin-bottom: 0px !important;
        overflow-x: scroll !important;
    }
    .sort-links a.active{
        border-bottom: none !important;
        padding-bottom: 15px !important;
    }
    .scrool-dots{
        display: flex;
        font-size: 20px;
    }
    .scrool-arrow{
        display: flex;
        font-size:20px;
        margin: 5px 0 0 5px;
    }
    .reset-flow{
        float: none !important;
        margin-bottom: 20px;
    }
}


</style>
