<script>
export default {
    props: ['app', 'attributes', 'aId', 'attribute', 'selectedProdId'],
    data: function () {
        return {
            product: this.app.products[this.selectedProdId],
            selectedVal: null,
            display: 1,
        }
    },
    watch: {
        selectedProdId: function () {
            this.product = this.app.products[this.selectedProdId]
            this.selectedVal = null
            this.attributes.selectedAttributes = []
            this.display = 1
            this.setDefaultValues()
        },
    },
    mounted: function ()  {
        this.setDefaultValues()
    },
    methods: {
        setDefaultValues: function () {
            for (let [valueId, value] of Object.entries(this.attribute)) {
                if (value.default == 1) {
                    this.addValue(valueId);
                    this.display = 0
                }
            }

            //if we have default values we don't display the attribute but add it to cart and go to the next step
            if (this.display == 0) {
                this.app.addToBasket(this.product, 1, this.attributes.selectedAttributes)
                this.displayStep4()
            }
        },
        setAttributesArray: function () {
            if (!this.attributes.selectedAttributes[this.aId]) {
                this.attributes.selectedAttributes[this.aId] = []
            }
        },
        setValue: function (valueId) {
            this.setAttributesArray()
            this.attributes.selectedAttributes[this.aId] = [valueId]
            return this.attributes.selectedAttributes
        },
        addValue: function (valueId) {
            this.setAttributesArray()
            this.attributes.selectedAttributes[this.aId].push(valueId)
            return this.attributes.selectedAttributes
        },
        displayStep4: function() {
            //this.app.basketStep == 3 - fix problem when we select attribute on the GroupPhoto.vue
            if (this.app.basketStep == 3) {
                this.app.basketStep = 4
                this.app.$nextTick(() => {
                    document.getElementById("step4").scrollIntoView({block: 'start', behavior: 'smooth'});
                });
            }
        },
        putToBasket: function (valueId) {
            this.setValue(valueId)
            this.app.addToBasket(this.product, 1, this.attributes.selectedAttributes)
            this.displayStep4()
        },
    },
}
</script>

<template>
    <div v-if="this.display == 1">

        <div class="row-holder">
            <div class="col-12">
                <div class="row">
                    <div class="image-holder b-shadow">
                        <div v-for="(value, valueId) in this.attribute" class="col-4 filter-block b-shadow attribute_value" @click="this.selectedVal = valueId; this.putToBasket(valueId);" :class="{'active': this.selectedVal == valueId}">
                            <div>
                                <div class="image-holder b-shadow" :class="{'gray-img': value.name == 'Black/White'}">
                                    <div class="frame-image">
                                        <strong class="sort">{{ trans(value.name) }}</strong>
                                    </div>
                                    <img class="not-retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + this.app.basket.image.img" alt="image description">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.attribute_value {
    float: left;
    padding: 0;
    margin: 30px;
}
</style>
