<script>
export default {
    props: ['app'],
    data: function () {
        return {

            w: 0,
            h: 0,
            bw: 3,
            zoom: 2,
            glass: {},
            img: {},
        }
    },
    methods: {
        nextGallery: function () {
            let number = this.app.galleryPhoto.number;

            number++
            if (this.app.photos[number]) {
                let img = this.app.photos[number]
                this.app.galleryPhoto = {number, img}
            }

            $(".img-magnifier-glass").remove();

        },
        prevGallery: function () {
            let number = this.app.galleryPhoto.number;

            number--
            if (this.app.photos[number]) {
                let img = this.app.photos[number]
                this.app.galleryPhoto = {number, img}
            }

            $(".img-magnifier-glass").remove();
        },
        magnify: function (imgID) {
            this.img = document.getElementById(imgID);
            this.zoom = 2;

            /* Create magnifier glass: */
            if (this.glass) $(".img-magnifier-glass").remove();
            this.glass = document.createElement("DIV");
            this.glass.setAttribute("class", "img-magnifier-glass");

            /* Insert magnifier glass: */
            this.img.parentElement.insertBefore(this.glass, this.img);

            /* Set background properties for the magnifier glass: */
            this.glass.style.backgroundImage = "url('" + this.img.src + "')";
            this.glass.style.backgroundRepeat = "no-repeat";
            this.glass.style.backgroundSize = (this.img.width * this.zoom) + "px " + (this.img.height * this.zoom) + "px";
            this.w = this.glass.offsetWidth / 2;
            this.h = this.glass.offsetHeight / 2;

            /* Execute a function when someone moves the magnifier glass over the image: */
            this.glass.addEventListener("mousemove", this.moveMagnifier, false);
            this.img.addEventListener("mousemove", this.moveMagnifier, false);

            /*and also for touch screens:*/
            this.glass.addEventListener("touchmove", this.moveMagnifier, false);
            this.img.addEventListener("touchmove", this.moveMagnifier, false);
        },
        moveMagnifier: function (e) {
            var pos, x, y;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = this.getCursorPos(e);

            x = pos.x;
            y = pos.y;

            /* Prevent the magnifier glass from being positioned outside the image: */
            if (x > this.img.width - (this.w / this.zoom)) {
                x = this.img.width - (this.w / this.zoom);
            }
            if (x < this.w / this.zoom) {
                x = this.w / this.zoom;
            }
            if (y > this.img.height - (this.h / this.zoom)) {
                y = this.img.height - (this.h / this.zoom);
            }
            if (y < this.h / this.zoom) {
                y = this.h / this.zoom;
            }
            /* Set the position of the magnifier glass: */
            this.glass.style.left = (x - this.w) + "px";
            this.glass.style.top = (y - this.h) + "px";
            /* Display what the magnifier glass "sees": */
            this.glass.style.backgroundPosition = "-" + ((x * this.zoom) - this.w + this.bw) + "px -" + ((y * this.zoom) - this.h + this.bw) + "px";
        },
        getCursorPos: function (e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /* Get the x and y positions of the image: */
            a = this.img.getBoundingClientRect();
            /* Calculate the cursor's x and y coordinates, relative to the image: */
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /* Consider any page scrolling: */
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x: x, y: y};
        },
        destroyZoom: function () {
            this.glass = {};
        },
    },
}
</script>

<template>
    <div class="gallery-holder slide-gallery" v-show="this.app.isGallery">
        <a href="javascript:void(0)" class="close" @click="this.app.isGallery = !this.app.isGallery"><i class="icon-close"></i></a>
        <div class="slide">
            <div class="image-holder b-shadow" v-if="this.app.galleryPhoto.img">
                <img class="not-retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + this.app.galleryPhoto.img" alt="image description" @mouseenter="this.magnify('galleryPhoto')" id="galleryPhoto">
                <img class="retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + this.app.galleryPhoto.img" alt="image description" @mouseenter="this.magnify('galleryPhotoR')" id="galleryPhotoR">
                <!-- fixme
                                    <img class="retina" src="/images/gallery-img@2x.jpg" alt="image description">
                -->
                <a href="javascript:void(0)" class="slide-arrow slide-prev slide-active" @click="this.prevGallery()"><i class="icon-slide-prev"></i></a>
                <a href="javascript:void(0)" class="slide-arrow slide-next slide-active" @click="this.nextGallery()"><i class="icon-slide-next"></i></a>
            </div>
            <div class="more-info b-shadow">
                <div class="id-number">
                    <span>#{{ this.app.galleryPhoto.number }}</span>
                </div>
                <div class="title-block">
                    <h3>{{ trans("Vælg billede") }}</h3>
                </div>
                <div class="zoom">
                    <a href="javascript:void(0)" @click="this.app.isGallery = !this.app.isGallery"><i class="icon-zoom-out"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
