<script>
export default {
    props: ['app'],
    data: function () {
        return {

            sliderNumber: 1,
        }
    },
    methods: {
        prevSlider: function () {
            if (this.sliderNumber === 1) {
                this.sliderNumber = 10;
            } else {
                this.sliderNumber--;
            }
        },
        nextSlider: function () {
            if (this.sliderNumber === 10) {
                this.sliderNumber = 1;
            } else {
                this.sliderNumber++;
            }
        },
    },
}
</script>

<template>
    <div class="gallerry-wrapper">
        <!--slide 01-->
        <div class="card-holder gallery-bg slide-1" :class="{'active-frame': this.sliderNumber == 1}" style="background-image: url('/images/frame-bg.jpg')">
            <div class="flex-holder">
                <div class="image-holder">
                    <div class="frame frame-30" style="background-image: url('/images/frame-30x40.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder">
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title">
                <h2>{{ trans("Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.") }}</h2>
            </div>
        </div>
        <!--slide 02-->
        <div class="card-holder gallery-bg" :class="{'active-frame': this.sliderNumber == 2}" style="background-image: url('/images/frame-bg.jpg')">
            <div class="flex-holder">
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder">
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title">
                <h2>{{ trans("Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.") }}</h2>
            </div>
        </div>
        <!--slide 03-->
        <div class="card-holder gallery-bg" :class="{'active-frame': this.sliderNumber == 3}" style="background-image: url('/images/frame-bg.jpg')">
            <div class="flex-holder">
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="flex-holder">
                        <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                            <img :src="this.app.basket.image.img" alt="">
                        </div>
                        <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                            <img :src="this.app.basket.image.img" alt="">
                        </div>
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title">
                <h2>{{ trans("Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.") }}</h2>
            </div>
        </div>
        <!--slide 04-->
        <div class="card-holder gallery-bg" :class="{'active-frame': this.sliderNumber == 4}" style="background-image: url('/images/frame-bg-1.jpg')">
            <div class="flex-holder reverse">
                <div class="image-holder">
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block margin">
                    <div class="frame frame-40-1" style="background-image: url('/images/frame-40x50-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title reverse margin-top">
                <h2>{{ trans("Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.") }}</h2>
            </div>
        </div>
        <!--slide 05-->
        <div class="card-holder gallery-bg slide-5" :class="{'active-frame': this.sliderNumber == 5}" style="background-image: url('/images/frame-bg-1.jpg')">
            <div class="flex-holder reverse">
                <div class="image-holder frame-block">
                    <div class="frame frame-21-1" style="background-image: url('/images/frame-21x30-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-30" style="background-image: url('/images/frame-30x40.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="flex-holder">
                        <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                            <img :src="this.app.basket.image.img" alt="">
                        </div>
                        <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                            <img :src="this.app.basket.image.img" alt="">
                        </div>
                    </div>
                </div>
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title reverse">
                <h2>{{ trans("Indramninger kan kun sendes til danske adresser,ellers kan de hentes på vores studie i Gentofte.") }}</h2>
            </div>
        </div>
        <!--slide 06-->
        <div class="card-holder gallery-bg" :class="{'active-frame': this.sliderNumber == 6}" style="background-image: url('/images/frame-bg-3.jpg')">
            <div class="flex-holder center">
                <div class="image-holder frame-block">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!--slide 07-->
        <div class="card-holder gallery-bg slide-7" :class="{'active-frame': this.sliderNumber == 7}" style="background-image: url('/images/frame-bg-3.jpg')">
            <div class="flex-holder center">
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-40" style="background-image: url('/images/frame-40x50.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="flex-holder inner">
                        <div class="image-holder">
                            <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                                <img :src="this.app.basket.image.img" alt="">
                            </div>
                        </div>
                        <div class="image-holder">
                            <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                                <img :src="this.app.basket.image.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-30" style="background-image: url('/images/frame-30x40.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
            <div class="title"><h2>Print med store rammer, der skal nedestående billede vises. Derudover er det kun nedestående billeder skal vises ved rammer som tilvalg, så pilene fjernes.</h2></div>
        </div>
        <!--slide 08-->
        <div class="card-holder slide-8 gallery-bg" :class="{'active-frame': this.sliderNumber == 8}" style="background-image: url('/images/frame-bg-3.jpg')">
            <div class="flex-holder center">
                <div class="image-holder frame-block">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-50-1" style="background-image: url('/images/frame-50x70-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!--slide 09-->
        <div class="card-holder gallery-bg slide-7" :class="{'active-frame': this.sliderNumber == 9}" style="background-image: url('/images/frame-bg-3.jpg')">
            <div class="flex-holder center">
                <div class="image-holder">
                    <div class="frame frame-50" style="background-image: url('/images/frame-50x70.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21" style="background-image: url('/images/frame-21x30.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="frame frame-21-1" style="background-image: url('/images/frame-21x30-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-40-1" style="background-image: url('/images/frame-40x50-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                    <div class="flex-holder inner">
                        <div class="image-holder">
                            <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                                <img :src="this.app.basket.image.img" alt="">
                            </div>
                        </div>
                        <div class="image-holder">
                            <div class="frame frame-13" style="background-image: url('/images/frame-13x18.png')">
                                <img :src="this.app.basket.image.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--slide 10-->
        <div class="card-holder slide-9 gallery-bg" :class="{'active-frame': this.sliderNumber == 10}" style="background-image: url('/images/frame-bg-4.jpg')">
            <div class="flex-holder center">
                <div class="image-holder frame-block">
                    <div class="frame frame-13-1" style="background-image: url('/images/frame-13x18-1.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
                <div class="image-holder frame-block">
                    <div class="frame frame-21-2" style="background-image: url('/images/frame-21x30-2.png')">
                        <img :src="this.app.basket.image.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" @click="this.prevSlider()" class="slide-arrow slide-prev slide-active"><i class="icon-slide-prev"></i></a>
        <a href="javascript:void(0)" @click="this.nextSlider()" class="slide-arrow slide-next slide-active"><i class="icon-slide-next"></i></a>
    </div>
</template>

<style scoped>

</style>
