<script>
import AddonItem from "./AddonItem.vue";

export default {
    components: {AddonItem},
    props: ['app', 'addons'],
    data: function () {
        return {

        }
    },
    methods: {
        setAllAddons: function () {

        },
        changeAddons: function (framePhoto, sign) {

        },
    },
}
</script>
<template>
    <div class="card-holder b-shadow">
        <div class="wrap-inner">
            <div class="card-block">
                <div class="title-holder">
                    <strong class="sort">{{ trans("Skal vi ramme det ind?") }}</strong>
                    <p>{{ trans("Du får dem sendt direkte hjem.") }}</p>
                    <p>{{ trans("Du skal ikke stå i kø i IKEA.") }}</p>
                    <p>{{ trans("Rammerne er med rigtigt glas og FSC mærket træ.") }}</p>
                </div>
<!--                <div class="total-price">-->
<!--                    <span class="price">,-</span>-->
<!--                    <a href="javascript:void(0)" @click="this.setAllAddons()" class="btn primary">{{ trans("vælger alle") }}</a>-->
<!--                </div>-->
            </div>
<!--            <div class="accordion-sort">-->
<!--                <ul class="opener">-->
<!--                    <li>-->
<!--                        <span class="item-description">-->
<!--                            <span style="vertical-align: inherit;">-->
<!--                                <span v-if="framePhoto.name === '13x18 i sort ramme '" style="vertical-align: inherit;">{{ framePhoto.name }} op til {{ framePhoto.quantity }} stk</span>-->
<!--                                <span v-else style="vertical-align: inherit;">{{ framePhoto.name }} (21x30) op til {{ framePhoto.quantity }} stk</span>-->
<!--                            </span>-->
<!--                        </span>-->
<!--                        <div class="add-box">-->
<!--                            <span class="price"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ framePhoto.price }}, -</span></span></span>-->
<!--                            <a href="javascript:void(0)" class="btn primary" @click="this.changeAddons(framePhoto, 'plus')">{{ trans("Tilføj") }}</a>-->
<!--                            <form action="#" class="count-items">-->
<!--                                <div class="input-holder">-->
<!--                                    <a class="btn-minus" href="javascript:void(0)" @click="this.changeAddons(framePhoto, 'minus')"><i class="icon-minus"></i></a>-->
<!--                                    <input :readonly="true">-->
<!--                                    <a class="btn-plus" href="javascript:void(0)" @click="this.changeAddons(framePhoto, 'plus')"><i class="icon-plus"></i></a>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <a href="javascript:void(0)" class="drop-sort">-->
<!--                    <span class="drop-title"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Se mulighederne") }}</span></span></span>-->
<!--                </a>-->
<!--            </div>-->
            <div class="accordion-sort" v-for="addon in this.addons">
                <AddonItem :app="this.app" :addon="addon" />
            </div>
        </div>
    </div>
</template>

<style scoped>

.accordion-sort .product_item {
    padding: 0 28px 28px 28px !important;
}

</style>
