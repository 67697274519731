<script>
export default {
    props: ['app'],
    data: function () {
        return {

        }
    },
    methods: {
        toStep1: function () {
            window.scrollTo(0, 0);
            this.app.successToCart = false
            this.app.basketStep = 1;
        },
    }
}
</script>

<template>
    <div class="add-to" v-if="this.app.successToCart">
        <div class="add-inner">
            <i class="icon-check-lg"></i>
            <h3>{{ trans("Din bestilling på dit første billede er lagt i kurven. Tak for det!") }}</h3>
            <ul class="add-to-list">
<!--                <li><a href="javascript:void(0)" @click="this.toStep1()">{{ trans("Bestil mere til det samme billede") }}</a></li>-->
                <li><a :href="this.app.startPage">{{ trans("Start ny bestilling på et af de andre billeder") }}</a></li>
                <li><a :href="this.app.startPage + '/checkout/true'">{{ trans("Gå til kurven") }}</a></li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
