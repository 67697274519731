<script>

import GroupPhotoProductItem from "./GroupPhotoProductItem.vue";

export default {
    components: {GroupPhotoProductItem},
    props: ['app', 'product'],
    data: function () {
        return {
        }
    },
    methods: {

    },
    mounted() {
        this.app.resetBasket()
    }
}
</script>

<template>
        <div class="step-blocks">
            <div class="step four row">
                <div class="card-holder b-shadow open">

                    <div class="image-holder">
                        <img class="not-retina" src="/images/blur-img.jpg" alt="image description">
                        <div class="info-holder">
                            <div class="icon-block">
                                <i class="icon-info"></i>
                            </div>
                            <div class="text">
                                <p>
                                    <span class="white-text">{{ trans("Billedet er til redigering") }}</span><br>
                                    {{ trans("Du kan trygt bestille det alligevel, da du altid kan returnere det igen, hvis du ikke er tilfreds.") }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card-block">
                        <div>
                            <GroupPhotoProductItem :app="this.app" :addon="product" />
                        </div>
                        <div class="opener" v-for="addon in product.addons">
                            <div>
                                <GroupPhotoProductItem :app="this.app" :addon="addon[0]" />
                            </div>
                        </div>
                        <div class="summary" >
                            <div class="container sm">
                                <div class="total-price">
                                    <div class="total-text">
                                        <span class="total">{{ trans("Total") }}</span>
                                        <span class="price">{{ this.app.basket.total }},-</span>
                                    </div>
                                    <button type="button" class="btn primary" href="javascript:void(0)" @click="this.app.addToOrder()">{{ trans("Tilføj til kurv") }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

</template>

<style scoped>
.sort{
    padding: 0 0 0 10px;
}
.total-price{
    justify-content: space-between;
}

@media (max-width: 767px) {
    .total-price .btn{
        height: 75px;
        margin-right: -10px;
    }
}

@media (min-width: 768px) {
    .total-price .btn{
        height: 75px;
        margin-right: -5px;
    }
}
</style>
