<script>
    import Header from "./Header.vue"
    export default {
        components: {
            Header,
        },
        data: function () {
            return {
                app: this,
                currentDomain: 'https://'+ window.location.hostname,
                answerIsOpen1: false,
                answerIsOpen2: false,
                answerIsOpen3: false,
                answerIsOpen4: false,
                answerIsOpen5: false,
                answerIsOpen6: false,
                answerIsOpen7: false,
                answerIsOpen8: false,

                bookingUrl: '',
                companyName: '',
                companyEmail: '',
                mainDomain: '',
                officialComapnyName: '',
                companyPhone: '',
                companyAddress: '',
                companyCity: '',
                companyId: '',
                logo: '',
                logoMobile: '',
            }
        },
        mounted: function () {
            this.bookingUrl = bookingUrl
            this.companyName = companyName
            this.companyEmail = companyEmail
            this.mainDomain = mainDomain
            this.officialComapnyName = officialComapnyName
            this.companyPhone = companyPhone
            this.companyAddress = companyAddress
            this.companyCity = companyCity
            this.companyId = companyId
            this.logo = logo
            this.logoMobile = logoMobile
        },
        methods: {

        },
    }
</script>

<template>
    <Header :app="app"/>
<div>
    <div id="main">
        <section class="title-section border">
                    <div class="container sm">
                        <h1><b>{{ trans("Ofte stillede spørgsmål " + this.companyName) }}</b></h1>
                    </div>
                </section>
        <section class="faq-section">
            <div class="container">
                <ul class="faq-accardion">
                    <li :class="{'active': answerIsOpen1}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen1 = !answerIsOpen1">{{ trans("Hvordan behandler i jeres billeder?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Vores billeder justeres i farve og skygge. Ønskes mere dybdegående billedbehandling vælges ”let billedbehandling” under tilvalg.") }}</p></div>
                    </li>
                    <li :class="{'active': answerIsOpen2}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen2 = !answerIsOpen2">{{ trans("Hvorfor kan jeg ikke se klassebilledet på forhånd?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Nej. Klassebilledet taget lang tid og producere og vi har derfor valgt at det ikke kan ses online. Der er dog altid 14 dages returret på det.") }}</p></div>
                    </li>
                    <li :class="{'active': answerIsOpen3}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen3 = !answerIsOpen3">{{ trans("Hvor kan man afhente sine billeder?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ this.companyAddress }}, {{ this.companyCity }}</p></div>
                    </li>
                    <li :class="{'active': answerIsOpen4}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen4 = !answerIsOpen4">{{ trans("I hvilket tidsrum kan jeg afhente mine billeder hos jer?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Vi har åbnet i vores studie mandag til torsdag kl. 9-17 og fredag 8-16. Vi har lukket i juli måned.") }}</p></div>
                    </li>
                    <li :class="{'active': answerIsOpen5}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen5 = !answerIsOpen5">{{ trans("Er det muligt at få trykt sine billeder i andre størrelser end dem i tilbyder her på siden?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Det er det! Skriv til " + this.companyEmail + " og vi finder ud af det.") }}</p></div>
                    </li>
                    <li :class="{'active': answerIsOpen6}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen6 = !answerIsOpen6">{{ trans("Tager i også familiepotrætter?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Nej, klassebilledet sælges kun printet – også i den digitale pakke.") }}</p></div>
                    </li>

                    <li :class="{'active': answerIsOpen7}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen7 = !answerIsOpen7">{{ trans("Kan jeg bestille klassebilledet digitalt?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Det kan du tro! Du kan bestille tid her: ") }}<a :href="this.bookingUrl">{{ this.bookingUrl }}</a> </p></div>
                    </li>
                    <li :class="{'active': answerIsOpen8}">
                        <a href="javascript:void(0)" class="opener" @click="answerIsOpen8 = !answerIsOpen8">{{ trans("Hvad er de tekniske detaljer for en HIGH-RESS fil?") }}<span class="open-close"></span></a>
                        <div class="drop"><p>{{ trans("Fil type: Jpg. Farveprofil RGB Adobe 1998 Størrelse 18x 24 cm, 2126 x 2835 300 Pixels/inch Filen kan uden problemer printe A4. Hvis den skal printes større, skal den interpoleres til rette størrelse først. Det skal gøres i et billedbehandlerprogram, vi anbefaler Photoshop eller Gimp. Interpoler ikke mere end 20% af billedets størrelse ad gangen. Hvis du selv printer, skal du selv interpolere billedet. Hvis du benytter en printleverandør på nettet, vil du automatisk blive guided gennem interpoleringen. Der er korn i vores filer, for at det skal se flottere ud på vores print. Hvis I åbner filen i eksempelvis Pictureviewer eller et andet program end et professionelt billedbehandlerprogram KAN I opleve at det ser MEGET kornet ud. Det betyder ikke noget, for det endelige udprint, da det er programmet, som ikke kan håndtere at vise billedet rigtigt.") }}</p></div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</div>
</template>
