import './bootstrap';

import { createApp } from 'vue'

import cookies from "./cookies.js";
import translations from "./translations.js";
import router from './router.js'

import Index from './components/Index.vue'
import Lang from "./components/Lang.vue";

const app = createApp({})

app
    .component('Index', Index)
    .component('Lang', Lang)
    .mixin(cookies)
    .mixin(translations)
    .use(router)

app.mount('root')
