<script>
    import Header from "./Header.vue"
    export default {
        components: {
            Header,
        },
        data: function () {
            return {
                app: this,
                currentDomain: 'https://'+ window.location.hostname,

                bookingUrl: '',
                companyName: '',
                companyEmail: '',
                mainDomain: '',
                mainDomainUrl: '',
                officialComapnyName: '',
                companyPhone: '',
                companyAddress: '',
                companyCity: '',
                companyId: '',
                logo: '',
                logoMobile: '',
            }
        },
        mounted() {
            this.bookingUrl = bookingUrl
            this.companyName = companyName
            this.companyEmail = companyEmail
            this.mainDomain = mainDomain
            this.officialComapnyName = officialComapnyName
            this.companyPhone = companyPhone
            this.companyAddress = companyAddress
            this.companyCity = companyCity
            this.companyId = companyId
            this.logo = logo
            this.logoMobile = logoMobile
        },
        methods: {
            showCartPopup: function () {
                $('#cartPopup').show(100);
            },
            closePopup: function () {
                $('body').removeClass('notScroll');
                $('body').removeClass('visual');
            },
        },
    }
</script>

<template>
    <Header :app="app"/>
    <div>
        <div>
            <main id="main">
                <section class="title-section border">
                    <div class="container sm">
                        <h1>{{ trans("HANDELSBETINGELSER") }}</h1>
                    </div>
                </section>
                <section class="terms-conditions">
                    <div class="container sm">
                        <ol class="terms-list">
                            <li>{{ trans("Betaling:") }}
                                <p>{{ trans("Alle priser er anført i DKK og inkl. moms.") }}</p>
                                <p>{{ trans("Ordren er først gennemført, når betalingen er gennemført. Hvis betalingen bliver annulleret, annulleres ordren. Hvis der ikke modtages betaling, sendes billederne ikke.") }}</p>
                                <p>{{ trans(this.officialComapnyName + " opbevarer hverken kortnummer, udløbsdato eller kontrolcifre.") }}</p>
                            </li>
                            <li>{{ trans("Levering:") }}
                                <p>{{ trans("Billederne leveres inden for 21 dage efter gennemført bestilling på hjemmesiden. Der tages dog forbehold for fejl i forhold til levering fra vores leverandørs side.") }}</p>
                                <p>{{ trans("Det er kundes eget ansvar at indtaste korrekt adresse og kontaktinfo.") }}</p>
                                <p></p>
                                <p>{{ trans("Hvis ordren ikke afhentes i pakkeshop, kommer ordren retur og kunden har herefter 30 dage til at kontakte " + this.officialComapnyName + " for at få ordren sendt påny. Dette vil koste et nyt forsendelses- og ekspeditionsgebyr.") }}</p>
                                <p>{{ trans("Dette vil koste et nyt forsendelses- og ekspeditionsgebyr.") }}</p>
                                <p>{{ trans("Afbudspolitik og ændring af ordre: Ordren kan ikke ændres efter gennemført betaling.") }}</p>
                                <p></p>
                                <p>{{ trans("Ved køb af digitale filer er det kundens eget ansvar at downloade billederne inden 31 dage.") }}</p>
                                <p>{{ trans("Gøres det ikke, bliver pengene ikke refunderet.") }}</p>
                            </li>
                            <li>{{ trans("Reklamation:") }}
                                <p>{{ trans("Har billederne taget skade under levering, kontakt da " + this.officialComapnyName) }}</p>
                                <p>{{ trans("Beskriv problemet, og vedhæft et billede af skaden/klagen, så tager vi os af det hurtigst muligt og sender nogle nye billeder, hvis skaden ikke er selvforskyldt.") }}</p>
                                <p>{{ trans("Dette gælder dog kun op til 5 dage efter levering. Husk at oplys ordrenummer.") }}</p>
                                <p>{{ trans("Hvis produktet stemmer overens med produktbeskrivelsen byttes produktet ikke.") }}</p>
                                <p>{{ trans("Der gives ingen garanti for farverfalmen over tid eller vandfasthed på billederne.") }}</p>
                                <p>{{ trans("Ved returnering af varer betaler kunden selv omkostninger ved forsendelse.") }}</p>
                                <p>{{ trans("Hver sag vil blive behandlet individuelt, og du bliver kontaktet af en medarbejder fra " + this.officialComapnyName + " ved klagesager.") }}</p>
                                <p>{{ trans("I overensstemmelse med købelovens regler har du som forbruger 24 måneders reklamationsret over mangler.") }}</p>
                            </li>
                            <li>{{ trans("Returret:") }}
                                <p>{{ trans("Billederne kan ikke returneres, medmindre der er reklamation på.") }}</p>
                            </li>
                            <li>{{ trans("Ændring af samhandelsbetingelser:") }}
                                <p>{{ trans(this.officialComapnyName + " ApS forbeholder sig retten til at ændre i samhandelsbetingelserne. ") }}</p>
                                <p>{{ trans("Hvis en ændring forekommer, træder den i kraft øjeblikkeligt og virker fremadrettet.") }}</p>
                            </li>
                        </ol>
                        <address>
                            <ul>
                                <li>{{ this.officialComapnyName }}</li>
                                <li>{{ this.companyAddress }}</li>
                                <li>{{ this.companyCity }}</li>
                                <li><a :href="'mailto:' + this.companyEmail">{{ this.companyEmail }}</a></li>
                            </ul>
                        </address>
                    </div>
                </section>
            </main>
        </div>
    </div>

</template>
