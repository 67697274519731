<script>
import AddonItem from "./AddonItem.vue";

export default {
    components: {AddonItem},
    props: ['app', 'addons'],
    data: function () {
        return {

        }
    },
    methods: {
    },
}
</script>

<template>
    <div class="card-block b-shadow card-block-hover" @click="this.app.checkboxClasses=!this.app.checkboxClasses">
        <div class="title-holder">
            <strong class="sort">{{ trans("Ekstra klassebillede") }}</strong>
        </div>
        <div class="total-price">
            <span class="price">100,-</span>
        </div>
<!--        <form class="checkbox-holder check" action="#">-->
<!--            <div class="toogle-holder">-->
<!--                <label class="switch">-->
<!--                    <input type="checkbox" :disabled="true">-->
<!--                    <span class="element"></span>-->
<!--                </label>-->
<!--            </div>-->
<!--        </form>-->

        <div class="product_item_wrapper" v-for="addon in this.addons">
            <AddonItem :app="this.app" :addon="addon" />
        </div>
    </div>
</template>

<style scoped>
.product_item_wrapper{
    margin-top: 40px;
}
.product_item{
    padding: 0;
}
</style>
