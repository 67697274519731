<script>
    import SignupHeader from "./SignupHeader.vue"
    export default {
        components: {
            SignupHeader,
        },
        data: function () {
            return {
                app: this,
                panelsDomain: 'https://' + window.panelsDomain + '/',
                currentDomain: 'https://' + window.location.hostname,
                startPage: 'https://' + window.location.hostname + '/signup/' + this.$route.params.school,
                isSignup: true,
                schoolName: '',
                classes: {},
                classNames: '',
                firstname: '',
                lastname: '',
                address: '',
                parent1Firstname: '',
                parent1Lastname: '',
                parent1Email: '',
                parent1Phone: '',
                parent2Email: '',
                parent2Phone: '',
                inputErrors: {},
                message: '',
                isErrors: false,
                isSuccess: false,
                showInputs: false,
                saveCustomers: [],
            }
        },
        computed: {

        },
        mounted: function () {
            this.inputErrors = {
                'firstname': false,
                'lastname': false,
                'address': false,
                'parent1Firstname': false,
                'parent1Lastname': false,
                'parent1Email': false,
                'parent1Phone': false,
            }

            this.getClasses();
        },
        watch: {

        },
        methods: {
            getClasses: function () {
                var url = this.panelsDomain + 'api/v1/webshop/signup',
                        data = new FormData();

                data.set('action', 'getData');
                data.set('organization_id', this.$route.params.school);

                axios.post(url, data, {
                    headers: {
                        'Accept': 'application/json',
                    }
                }).then(response => {
                    if (!response.data.classes || !response.data.school.name) {
                        return false;
                    }
                    this.classes = response.data.classes;
                    this.schoolName = response.data.school.name;


                }).catch(error => {
                    this.isSuccess = false;
                    this.isErrors = true;
                    this.message = "Incorrect link. Please contact the administrator";
                    console.log(error);
                })
            },
            saveSignupForm: function () {
                this.message = '';
                this.isErrors = false;
                this.isSuccess = false;

                let i;
                for (i in this.inputErrors) {
                    if (typeof this[i] === 'undefined' || this[i] === null || this[i] === '') {
                        this.isErrors = true;
                        this.inputErrors[i] = true;
                        this.message = "Necessary form fields are not filled out\n";
                        continue;
                    } else {
                        this.inputErrors[i] = false;
                    }
                }

                if (!this.isErrors) {
                    var url = this.panelsDomain + 'api/v1/webshop/signup',
                            data = new FormData();

                    data.set('action', 'saveSignupForm');
                    data.set('organization_id', this.$route.params.school);
                    data.set('class', this.classNames);
                    data.set('firstname', this.firstname);
                    data.set('lastname', this.lastname);
                    data.set('address', this.address);
                    data.set('relative1_firstname', this.parent1Firstname);
                    data.set('relative1_lastname', this.parent1Lastname);
                    data.set('relative1_email', this.parent1Email);
                    data.set('relative1_phone', this.parent1Phone);
                    data.set('relative2_email', this.parent2Email);
                    data.set('relative2_phone', this.parent2Phone);

                    axios.post(url, data, {
                        headers: {
                            'Accept': 'application/json',
                        }
                    }).then(response => {
                        this.isSuccess = true;
                        this.message = "Forms save";
                        const serializeData = {};
                        [...data.keys()].forEach((key) => {
                           serializeData[key] = data.get(key);
                        });
                        this.saveCustomers.push(serializeData);
                    }).catch(error => {
                        this.isSuccess = false;
                        this.isErrors = true;
                        this.message = "500 Server Error. Please contact the administrator";
                        console.log(error);
                    })
                }
            },
            addStudent: function () {
                this.classNames = '';
                this.lastname = '';
                this.firstname = '';
                this.isErrors = false;
                this.isSuccess = false;
                this.getClasses();
            }
        }
    }
</script>
<template>
    <link href="/css/bootstrap.min.css" rel="stylesheet">
    <SignupHeader  :app="app" />
    <div class="container-sm signup-forms" >
        <h1>{{ trans("Tilmelding til skolefotografering (Sign up form)") }}</h1>
        <div class="alert alert-danger" role="alert" v-if="this.isErrors && this.message">{{ this.message }}</div>
        <div class="alert alert-success" role="alert" v-if="this.isSuccess && this.message">{{ this.message}}</div>
        <div class="alert alert-dark" role="alert" v-if="this.isSuccess">
            <p>{{ trans("Tak for at tilmelde dit barn til skolefotografering du har tilmeldt") }}</p>
            <ul class="list-group">
                <li class="list-group-item" v-for="customer in this.saveCustomers">{{ customer.firstname + ' ' + customer.lastname }}</li>
            </ul>
            <p>{{ trans("Ønsker du at tilmelde flere børn") }} <button type="button" @click="addStudent()" class="btn btn-primary">add student</button></p>
        </div>
        <form v-if="!this.isSuccess" @submit.prevent="saveSignupForm">
            <div class="mb-3" v-if="this.schoolName">
                <label class="form-label">Skole (School)</label>
                <p v-if="this.schoolName"><strong>{{ this.schoolName }}</strong></p>
            </div>
            <div class="mb-3">
                <label class="form-label">{{ trans("Classes") }}:</label>
                <select class="form-select form-control" v-model="classNames">
                    <option disabled value="">{{ trans("Select class") }}</option>
                    <option v-for="className in classes" v-bind:value="className">
                        {{ className }}
                    </option>
                </select>
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Student First Name") }}:</label>
                <input type="text" class="form-control"   name="firstname" v-model="this.app.firstname" :class="{'is-invalid': inputErrors.firstname}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Student Last Name") }}:</label>
                <input type="text" class="form-control"  name="lastname" v-model="lastname" :class="{'is-invalid': inputErrors.lastname}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Parent First Name") }}:</label>
                <input type="text" class="form-control" name="parent1Firstname" v-model="parent1Firstname" :class="{'is-invalid': inputErrors.parent1Firstname}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label  class="form-label">{{ trans("Parent Last Name") }}:</label>
                <input type="text" class="form-control" name="parent1Lastname" v-model="parent1Lastname" :class="{'is-invalid': inputErrors.parent1Lastname}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Address") }}:</label>
                <input type="text" class="form-control" name="address" v-model="address" :class="{'is-invalid': inputErrors.address}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label  class="form-label">{{ trans("Parent 1 email") }}:</label>
                <input type="email" class="form-control" name="parent1Email" v-model="parent1Email" :class="{'is-invalid': inputErrors.parent1Email}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Parent 1 phone (8 digits)") }}:</label>
                <input type="tel" pattern="[0-9]{8}" class="form-control" name="parent1Phone" v-model="parent1Phone" :class="{'is-invalid': inputErrors.parent1Phone}">
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label  class="form-label">{{ trans("Parent 2 email") }}:</label>
                <input type="email" class="form-control" name="parent2Email" v-model="parent2Email" >
            </div>
            <div class="mb-3" v-show="this.classNames">
                <label class="form-label">{{ trans("Parent 2 phone  (8 digits)") }}:</label>
                <input type="tel" pattern="[0-9]{8}" class="form-control" name="parent2Phone" v-model="parent2Phone" >
            </div>
            <div class="gap-2" v-show="this.classNames" :class="{'d-grid': this.classNames}">
                <button type="submit" class="btn btn-primary">{{ trans("Submit") }}</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
    .signup-forms{
        margin-top: 100px;
    }
</style>

