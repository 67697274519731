<script>
export default {
    props: ['app', 'steps'],
    data: function () {
        return {

            digitalFiles: {},
            digitalFilesIsFull: false,
            digitalFilesTotal: 0,
            digitalFilesQnt: 5,
        }
    },
    methods: {
        settingDigitalFiles: function (photoNum, color) {
            this.digitalFiles[photoNum][color] = !this.digitalFiles[photoNum][color];

            if (this.digitalFiles[photoNum][color] && !this.digitalFilesIsFull)
                this.digitalFilesTotal++;
            else
                this.digitalFilesTotal--;

            if (this.digitalFilesTotal == this.digitalFilesQnt) {
                this.digitalFilesIsFull = true;
                this.app.basketStep = 4;
            } else {
                this.digitalFilesIsFull = false;
                this.app.basketStep = 3;
            }
        },
        openGalleryProductset: function (img) {
            this.app.isMultipleGallery = true;
            this.app.multipleGalleryImg = img;
        },
    },
}
</script>

<template>
    <div class="step-title">
        <span class="count-item">3</span>
        <h3>{{ trans("Antal valgte filer") }} {{ this.digitalFilesTotal }} / {{ this.digitalFilesQnt }}</h3>
    </div>
    <div class="gallery-holder">
        <div class="row-holder">
            <div class="col-4" v-for="photo in this.app.photos">
                <div class="sort-block" :class="{'active': this.digitalFiles[photo.number]['color'] || this.digitalFiles[photo.number]['bw']}">
                    <div class="image-holder">
                        <img class="not-retina" :src="photo.img" alt="image description">
                        <img class="retina" :src="photo.img" alt="image description">
                    </div>
                    <div class="more-info">
                        <div class="id-number">
                            <span>#{{ photo.number }}</span>
                        </div>
                        <div class="zoom">
                            <a href="javascript:void(0)" @click="this.openGalleryProductset(photo.img)"><i class="icon-zoom"></i></a>
                        </div>
                    </div>
                    <div class="checkboxes">
                        <form class="checkbox-holder check" action="#">
                            <div class="col-6">
                                <label class="switch">
                                    <input type="checkbox" @click="this.settingDigitalFiles(photo.number,'color')" :disabled="this.digitalFilesIsFull && !this.digitalFiles[photo.number]['color']">
                                    <span class="element"></span>
                                    <span class="sort-name">{{ trans("Farve") }}</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <label class="switch">
                                    <input type="checkbox" @click="this.settingDigitalFiles(photo.number,'bw')" :disabled="this.digitalFilesIsFull && !this.digitalFiles[photo.number]['bw']">
                                    <span class="element"></span>
                                    <span class="sort-name">{{ trans("Sort / hvid") }}</span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
