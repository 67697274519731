<script>

export default {
    props: ['app', 'attributes', 'selectedProdId', 'currentQty'],
    data: function () {
        return {
            selectedAttributes: [],
            product: this.app.products[this.selectedProdId],
            selectedVal: null,
        }
    },
    watch: {
        currentQty: function () {
            if (this.currentQty > 0) {
                this.product = this.app.products[this.selectedProdId]
                this.selectedVal = null
                this.selectedAttributes = []
                this.setValues()
            }
        },
    },
    methods: {
        getDefValues: function (aValues) {
            let defValues = []
            for (let [attributeValueId, attributeValue] of Object.entries(aValues)) {
                if (attributeValue.default == 1) {
                    defValues.push(attributeValueId)
                }
            }

            return defValues
        },
        getSelectedValues: function (aId) {
            let selectedValues = []
            if (this.app.basket.products[this.app.prodId].selectedAttributes
                && this.app.basket.products[this.app.prodId].selectedAttributes[aId]) {
                selectedValues = this.app.basket.products[this.app.prodId].selectedAttributes[aId]
            }

            return selectedValues
        },
        getValues: function (aId, aValues) {
            let values = []
            let selectedValues = this.getSelectedValues(aId)
            let defValues = this.getDefValues(aValues)

            values = selectedValues
            if (defValues.length > 0) {
                values = defValues
            }

            return values
        },
        setValues: function () {
            if ( this.selectedProdId === null) {
                return
            }

            for (let [aId, aValues] of Object.entries(this.attributes)) {
                let values = this.getValues(aId, aValues)
                for (let [key, valueId] of Object.entries(values)) {
                    this.addValue(aId, valueId)
                }
            }

            this.app.addToBasket(this.product, this.currentQty, this.selectedAttributes)
        },
        addValue: function (aId, valueId) {
            if (!this.selectedAttributes[aId]) {
                this.selectedAttributes[aId] = []
            }
            this.selectedAttributes[aId].push(valueId)
            return this.selectedAttributes
        },
    },
}
</script>

<template>

</template>

<style scoped>

</style>
