<script>
import AddonItem from "./AddonItem.vue";

export default {
    components: {AddonItem},
    props: ['app', 'addons'],
    data: function () {
        return {

        }
    },
    methods: {
        changeExtras: function(addon, sign)
        {
        },
    },
}
</script>

<template>
    <div class="card-holder b-shadow">
        <div class="wrap-inner">
            <div class="card-block">
                <div class="title-holder">
                    <strong class="sort"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Du har nu opnået 1⁄2 pris på ekstra print af samme billede.") }}</span></span></strong>
                </div>
<!--                <div class="total-price">-->
<!--                    <span class="value"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Fra") }}</span></span></span>-->
<!--                    <span class="price"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">, -</span></span></span>-->
<!--                    <span class="value"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Normalpris") }}</span></span></span>-->
<!--                    <span class="price"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">, -</span></span></span>-->
<!--                    <a href="javascript:void(0)" class="btn primary"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Spar 50%") }}</span></span></a>-->
<!--                </div>-->
<!--                <div class="title-holder">-->
<!--                    <strong class="sort"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ trans("Yderligere rabat ved køb af endnu flere print.") }}</span></span></strong>-->
<!--                </div>-->
            </div>
<!--            <div class="accordion-sort">-->
<!--                <ul class="opener">-->
<!--                    <li v-for="addon in this.addons">-->
<!--                        <span class="item-description"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ addon.name }}</span></span></span>-->
<!--                        <div class="add-box">-->
<!--                            <span class="price"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">{{ addon.priceDiscount }}, -</span></span></span>-->
<!--                            <a href="javascript:void(0)" class="btn primary" @click="this.changeExtras(addon, 'plus')">{{ trans("Tilføj") }}</a>-->
<!--                            <form action="#" class="count-items">-->
<!--                                <div class="input-holder">-->
<!--                                    <a class="btn-minus" href="javascript:void(0)" @click="this.changeExtras(addon, 'minus')"><i class="icon-minus"></i></a>-->
<!--                                    <input :readonly="true">-->
<!--                                    <a class="btn-plus" href="javascript:void(0)" @click="this.changeExtras(addon, 'plus')"><i class="icon-plus"></i></a>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <a href="javascript:void(0)" class="drop-sort">-->
<!--                    <span class="drop-title">{{ trans("Se mulighederne") }}</span>-->
<!--                </a>-->
<!--            </div>-->

            <div class="accordion-sort" v-for="addon in this.addons">
                <AddonItem :app="this.app" :addon="addon" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion-sort .product_item {
    padding: 0 28px 28px 28px !important;
}

</style>
