import { createRouter, createWebHistory } from 'vue-router'

import Index from "./components/Index.vue"
import Signup from "./components/Signup/Signup.vue";
import Download from "./components/Download/Download.vue";
import Terms from "./components/Info/Terms.vue";
import Faq from "./components/Info/Faq.vue";
import Success from "./components/Info/Success.vue";
import Cancel from "./components/Info/Cancel.vue";
import NotFound from "./components/Info/NotFound.vue";
import Payment from "./components/Payment.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/customer/:customer/order/:order/token/:token', component: Index },
        { path: '/customer/:customer/order/:order/token/:token/cart/:cart', component: Index },
        { path: '/customer/:customer/order/:order/token/:token/checkout/:checkout', component: Index },
        { path: '/signup/:school', component: Signup },
        { path: '/download/customer/:customer/token/:token', component: Download },
        { path: '/terms', component: Terms },
        { path: '/faq', component: Faq },
        { path: '/success/order/:order/token/:token', component: Success },
        { path: '/cancel', component: Cancel },
        { path: '/payment/order/:order/token/:token', component: Payment },
        {
            path: '/',
            component: {},
            beforeEnter: (to, from) => {
                window.location.href = redirectUrl
            },
        },
        {
            path: "/:notFound",
            component: NotFound,
        },
    ]
})
