<script>
import DownloadHeader from "./DownloadHeader.vue"
export default {
    components: {
        DownloadHeader,
    },
    data: function () {
        return {
            app: this,
            panelsDomain: 'https://' + window.panelsDomain + '/',
            currentDomain: 'https://' + window.location.hostname,
            relatives: {},
            id: '',
            token: '',
            photos: {},
            isPhotos: false,
            photosHigh: {},
            noPhotos: false,
        }
    },
    computed: {

    },
    mounted: function () {
        this.id = this.$route.params.customer
        this.token = this.$route.params.token
        this.getPhotosToDownload()
    },
    watch: {

    },
    methods: {
        getPhotosToDownload: function () {
            var url = this.panelsDomain + 'api/v1/webshop',
                data = new FormData();

            data.set('action', 'getPhotosToDownload');
            data.set('id', this.id);
            data.set('token', this.token);

            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {

                if (!response.data.photos && !response.data.photosHigh) {
                    this.noPhotos = true;
                    return false;
                }
                this.isPhotos = true;

                if (response.data.photos) {
                    this.photos = Object.assign({}, response.data.photos);
                }
                if (response.data.photosHigh) {
                    this.photosHigh = Object.assign({}, response.data.photosHigh);
                }

            }).catch(function (error) {
                console.log(error);
            })
        },
    }
}
</script>
<template>
    <link href="/css/bootstrap.min.css" rel="stylesheet">
    <DownloadHeader :app="app" :isPhotos="this.isPhotos" />
    <div class="container-sm download-block" >
        <h2>Yours Photos</h2>
        <div class="row">
            <div class="col-md-12">
                <div class=" row">
                    <div class="row row-cols-1 row-cols-md-3" >
                        <div class="col-4 mb-4" v-for="photo in this.photosHigh">
                            <div class="card">
                                <img :src="this.panelsDomain + photo.path" class="card-img-top" alt="image description">
                                <div class="card-body">
                                    <h5 class="card-title text-center">{{ trans("High resolution") }}</h5>
                                    <p class="card-text text-center">{{ trans("Bought") }} {{ photo.year }}</p>
                                    <a :href="this.panelsDomain + photo.path" type="button" class="btn btn-dark btn-lg btn-block m-1" download>{{ trans("Download") }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 mb-4" v-for="photo in this.photos">
                            <div class="card">
                                <img :src="this.panelsDomain + photo.path" class="card-img-top" alt="image description">
                                <div class="card-body">
                                    <a :href="this.panelsDomain + photo.path" type="button" class="btn btn-dark btn-lg btn-block m-1" download>{{ trans("Download") }}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="alert alert-danger" role="alert" v-if="noPhotos">
                    {{ trans("The customer does not have photos") }}
                </div>
                <iframe id="photo_uploading" :src="this.app.panelsDomain + 'a258tz/PhotoUploadsIndex/' + this.id" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>
<style scoped>
.download-block{
    margin-top: 100px;
}
#photo_uploading {
    width: 100%;
    height: 500px;
    display: block;

}
</style>

