<script>

export default {
    props: ['app'],
    data: function () {
        return {

        }
    },
    mounted() {
        this.app.resetBasket()
    },
    methods: {
        closeCartPopup: function () {
            console.log(this.$parent.$options.el);
            $('#cartPopup').hide(100);
        },
        getAttributeValueName(cartProduct) {
            let attributeValueName = '';
            let product = this.app.products[cartProduct.id]

            for (let [attributeId, attributes] of Object.entries(product.attributes)) {
                let orderedAttributeId = cartProduct.attributes[attributeId]
                if (attributes[orderedAttributeId]) {
                    attributeValueName = attributes[orderedAttributeId].name
                    return attributeValueName
                }
            }

            return attributeValueName
        },
    }
}
</script>

<template>
    <div>
        <div>
            <!--<header id="header" v-if="this.$parent.$options.el != '#cartPopup'">
                <div class="container lg">
                    <div class="left-side">
                        <div class="logo">
                            <a :href="this.app.startPage">
                                <img src="/images/say_orange.png" alt="" class="mobile-logo">
                                <img src="/images/say_orange.png" alt="" class="desktop-logo not-retina">
                                <img src="/images/say_orange.png" alt="" class="desktop-logo retina">
                            </a>
                        </div>
                        <ul class="breadcrumbs">
                            <li><a href="javascript:void(0)">{{ this.app.firstname }}</a></li>
                            <li><a href="javascript:void(0)">{{ this.app.className }}</a></li>
                            <li><a href="javascript:void(0)" @click="isProductSet = !isProductSet">{{ trans("Billeder") }}</a></li>
                            <li><a href="javascript:void(0)">{{ this.app.customerId }}</a></li>
                            <li class="active"><a href="javascript:void(0)">{{ trans("Din kurv") }}</a></li>
                        </ul>
                    </div>
                    <Lang></Lang>
                    <div class="right-side">
                    </div>
                </div>
            </header>-->
            <div id="main">
                <div class="timer container lg" v-if="this.app.isTimer">
                    <div class="text">
                        <p>{{ trans("Bestil inden deadline og få billederne leveret på skolen") }} <span>{{ this.app.printDeadlineTime }}</span></p>
                    </div>
                </div>
                <div>
                    <section class="closeCartPopupParent">
                        <a class="closeCartPopup" href="javascript:void(0)" @click="closeCartPopup()"><i class="icon-close"></i></a>
                    </section>
                    <section class="title-section border">
                        <div class="container sm">
                            <h1>{{ trans("Din kurv") }}</h1>
                            <a :href="this.app.startPage" class="btn secondary b-shadow">{{ trans("Shop videre") }}</a>
                        </div>
                    </section>
                    <section class="btn-section">
                        <div class="container sm">
                            <div class="btn-holder">
                                <a :href="this.app.startPage + '/checkout/true'" v-if="this.app.cart.cartTotal>0" class="btn primary b-shadow">{{ trans("Gå til kassen") }}</a>
                            </div>
                        </div>
                    </section>
                    <section class="progress-bar border">
                        <div class="container sm">
                            <span class="progress-info">{{ trans("Du mangler kun at shoppe for 185,- mere for at opnå gratis levering til døren") }}</span>
                            <progress max="100" value="75"></progress>
                        </div>
                    </section>
                    <section class="product-wrapper">
                        <div class="container sm">
                            <div class="product-item b-shadow" v-for="product in this.app.cart.products">
                                <div class="product">
                                    <div class="product-info">
                                        <div v-if="this.app.groupPhotoProducts.includes(product.id.toString())">
                                            <div class="image-holder b-shadow" :class="{'gray-img': this.getAttributeValueName(product) == 'Black/White'}">
                                                <img class="not-retina customer_image" :src="this.app.currentDomain + '/images/blur-img.jpg'" alt="image description">
                                                <img class="retina" :src="this.app.currentDomain + '/images/blur-img.jpg'" alt="image description">
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="image-holder b-shadow" :class="{'gray-img': this.getAttributeValueName(product) == 'Black/White'}" v-if="product.img">
                                                <img class="not-retina customer_image" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + product.img" alt="image description">
                                                <img class="retina" :src="this.app.panelsDomain + 'storage/photos/img_select/' + this.app.customerId + '/' + product.img" alt="image description">
                                            </div>
                                            <div class="image-holder b-shadow" :class="{'gray-img': this.getAttributeValueName(product) == 'Black/White'}" v-else="product.img">
                                                <img class="not-retina customer_image" :src="this.app.panelsDomain + 'storage/photos/img_select/premade.jpg'" alt="image description">
                                                <img class="retina" :src="this.app.panelsDomain + 'storage/photos/img_select/premade.jpg'" alt="image description">
                                            </div>
                                        </div>
                                        <div class="text">
                                            <strong class="product-title">{{ trans("Billede #") }}{{ product.imagenumber }}</strong>
                                            <ul class="product-description">
                                                <li>
                                                    <span class="key">{{ trans("Produkt") }}</span>
                                                    <div class="value-holder">
                                                        <span class="value">{{ trans(product.name) }}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span class="key">{{ trans("Farve") }}</span>
                                                    <div class="value-holder">
                                                        <span class="value">{{ trans(this.getAttributeValueName(product)) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a class="close" href="javascript:void(0)" @click="this.app.delFromCart(product)">
                                        <img class="" src="/images/remove-product.png" alt="image description">
                                    </a>
                                    <span class="product-price">{{product.quantity}} {{trans("pcs")}}  x {{ product.price }}, -</span>
                                </div>

<!--                                todo: figure out with the extra -->
<!--                                <div class="product-options" v-if="product.extra != undefined && product.extra.length != 0">-->
<!--                                    <span class="option-title">{{ trans("Tilvalg") }}</span>-->
<!--                                    <ul class="product-description">-->
<!--                                        <li v-if="product.extra.digital">-->
<!--                                            <span class="key">{{ trans("Med stor digital fil") }}</span>-->
<!--                                            <span class="value">39,-</span>-->
<!--                                        </li>-->
<!--                                        <li v-for="rammer in product.extra.rammer">-->
<!--                                            <span class="key"> {{ rammer.quantity }} stk. {{ rammer.name }}</span>-->
<!--                                            <span class="value">{{ rammer.price }},-</span>-->
<!--                                            <a class="bottom-close" href="javascript:void(0)" @click="this.app.delFromCart(rammer)"><img class="" src="/images/remove-product.png" alt="image description"></a>-->
<!--                                        </li>-->
<!--                                        <li v-for="sheet in product.extra.sheet">-->
<!--                                            <span class="key">{{ sheet.quantity }} stk. {{ sheet.name }}</span>-->
<!--                                            <span class="value">{{ sheet.price }},-</span>-->
<!--                                            <a class="bottom-close" href="javascript:void(0)" @click="this.app.delFromCart(sheet)"><img class="" src="/images/remove-product.png" alt="image description"></a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->

                            </div>
                        </div>
                    </section>

                    <section class="total-section">
                        <div class="container sm">
                            <div class="total-block max-499" v-if="this.app.free_shipping">
                                <h2>{{ trans("Tillykke, du har opnået gratis fragt lide til døren!") }}</h2>
                            </div>
                            <div class="total-block">
                                <div class="total-text">
                                    <strong class="total">{{ trans("Total") }}</strong>
                                    <span class="total-slogan">{{ trans("ekskl. levering") }}</span>
                                </div>
                                <span class="total-price">{{ this.app.cart.cartTotal }},-</span>
                            </div>
                        </div>
                    </section>

                    <section class="btn-section">
                        <div class="container sm">
                            <div class="btn-holder">
                                <a :href="this.app.startPage" class="btn secondary b-shadow">{{ trans("Shop videre") }}</a>
                                <a :href="this.app.startPage + '/checkout/true'" v-if="this.app.cart.cartTotal>0" class="btn primary b-shadow">{{ trans("Gå til kassen") }}</a>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.customer_image {
    max-width: 150px !important;
}
</style>
