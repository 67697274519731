<script>
export default {
    props: ['app', 'currentAdderess'],
    data: function () {
        return {
            isAgreeTermCond: false,
            inputErrors: {},
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            zipcode: '',
            city: '',
            sibling_token: '',
            formError: '',
            delivery: {},
            discount: {},
            selectedDelivery: null,
            selectedDeliverySystem: null,
            //todo: fix hardcode
            selectedPayment: 1,
            deliverytypesConfig: {
                home: '',
                school: '',
                siblings: '',
                studio: '',
            },
            deliverysystemsConfig: {
                gls: '',
                dao: '',
            },
        }
    },
    computed: {
        totalPrice: function () {
            this.app.cart.cartTotal = this.app.cart.orderSum
            if (!!this.delivery && !!this.delivery.price) {
                this.app.cart.cartTotal += this.delivery.price
            }

            if (!!this.discount && !!this.discount.discount_amount) {
                this.app.cart.cartTotal -= this.discount.discount_amount
            }

            return this.app.cart.cartTotal
        },
    },
    mounted: function () {
        this.deliverytypesConfig = deliverytypesConfig
        this.deliverysystemsConfig = deliverysystemsConfig
        this.inputErrors = {
            'firstname': false,
            'lastname': false,
            'phone': false,
            'email': false,
            'address': false,
            'zipcode': false,
            'city': false,
        }
        this.app.resetBasket()
    },
    updated: function () {
        if (this.selectedDelivery === null) {
            this.selectedDelivery = this.app.cart.selectedDelivery
        }
    },
    watch: {
        selectedDelivery: function () {
            this.delivery = this.app.deliverytypes[this.selectedDelivery]


            if (this.selectedDelivery == this.deliverytypesConfig.school) {
                //todo: fix hardcode (match DeliverySystem and DeliveryType dynamicaly or in the panel)
                this.selectedDeliverySystem = this.deliverysystemsConfig.gls
                if (this.inputErrors.sibling_token === false) {
                    delete this.inputErrors.sibling_token
                }
            } else if (this.selectedDelivery == this.deliverytypesConfig.home) {
                this.selectedDeliverySystem = this.deliverysystemsConfig.dao
                if (this.inputErrors.sibling_token === false) {
                    delete this.inputErrors.sibling_token
                }
            } else if (this.selectedDelivery == this.deliverytypesConfig.siblings) {
                this.selectedDeliverySystem = this.deliverysystemsConfig.dao
                if (!this.inputErrors.sibling_token) {
                    this.inputErrors.sibling_token = false
                }
            } else if (this.selectedDelivery == this.deliverytypesConfig.downloading) {
                this.selectedDeliverySystem = this.deliverysystemsConfig.nothing
                if (this.inputErrors.sibling_token === false) {
                    delete this.inputErrors.sibling_token
                }
            } else if (this.selectedDelivery == this.deliverytypesConfig.studio) {
                this.selectedDeliverySystem = this.deliverysystemsConfig.nothing
                if (this.inputErrors.sibling_token === false) {
                    delete this.inputErrors.sibling_token
                }
            }
        }
    },
    methods: {
        submitOrder: function () {
            this.formError = '';

            let i;
            for (i in this.inputErrors) {
                if (typeof this[i] === 'undefined' || this[i] === null || this[i] === '') {
                    $('input[name="' + i + '"]').css('border-color', 'red');
                    this.formError = "Necessary form fields are not filled out\n";
                    continue;
                } else {
                    $('input[name="' + i + '"]').css('border-color', '#d6d6d6');
                }
            }

            if (!this.isAgreeTermCond) {
                this.formError += "See terms and conditions\n";
            }

            if (!this.formError) {
                //send order info to server
                var url = this.app.panelsDomain + 'api/v1/webshop',
                    serverAnswerIsSuccess = false,
                    amount = '',
                    payment_id = '',
                    cancelurl = '',
                    callbackurl = '',
                    continueurl = '',
                    checksum = '',
                    autocapture = '',
                    currency = '',
                    agreement_id = '',
                    merchant_id = '',
                    version = '',
                    dataOrder = new FormData();

                dataOrder.set('action', 'submitOrder');
                dataOrder.set('order_id', this.app.order_id);
                dataOrder.set('id', this.app.id);
                dataOrder.set('token', this.app.token);

                dataOrder.set('firstname', this.firstname);
                dataOrder.set('lastname', this.lastname);
                dataOrder.set('email', this.email);
                dataOrder.set('phone', this.phone);
                dataOrder.set('city', this.city);
                dataOrder.set('address', this.address);
                dataOrder.set('zipcode', this.zipcode);

                dataOrder.set('deliverytype_id', this.selectedDelivery)
                dataOrder.set('deliverysystem_id', this.selectedDeliverySystem)
                dataOrder.set('totalprice', this.totalPrice)
                dataOrder.set('paymentsystem', this.selectedPayment)
                dataOrder.set('sibling_token', this.sibling_token)

                axios.post(url, dataOrder, {
                    headers: {
                        'Accept': 'application/json',
                    }
                }).then(response => {

                    this.paymentRequest(response.data);

                }).catch(function (error) {
                    var errorsSting = ''
                    if (!!error.response.data.message) {
                        errorsSting += "<p class='error_message'>" + error.response.data.message + "</p>";
                    } else if (error.response.data.errors) {
                        for (let [field, errors] of Object.entries(error.response.data.errors)) {
                            for (let [key, errorMsg] of Object.entries(errors)) {
                                errorsSting += "<p class='error_message'>" + errorMsg + "</p>";
                            }
                        }
                    }
                    $('#error_messages').append(errorsSting);
                })
            }
        },
        paymentRequest: function (data) {
            console.log(data.amount);

            var url = 'https://payment.quickpay.net',
                str = "<form method='POST' action='" + url + "'>\n";

            //todo: fix hardcode
            str += "<input type='hidden' name='version' value='" + data.version + "'></input>";
            str += "<input type='hidden' name='merchant_id' value='" + data.merchant_id + "'></input>";
            str += "<input type='hidden' name='agreement_id' value='" + data.agreement_id + "'></input>";
            str += "<input type='hidden' name='currency' value='" + data.currency + "'></input>";
            str += "<input type='hidden' name='autocapture' value='" + data.autocapture + "'></input>";
            str += "<input type='hidden' name='order_id' value='" + data.payment_id + "'></input>";
            str += "<input type='hidden' name='amount' value='" + data.amount + "'></input>";
            str += "<input type='hidden' name='continueurl' value='" + data.continueurl + "'></input>";
            str += "<input type='hidden' name='callbackurl' value='" + data.callbackurl + "'></input>";
            str += "<input type='hidden' name='cancelurl' value='" + data.cancelurl + "'></input>";
            str += "<input type='hidden' name='checksum' value='" + data.checksum + "'></input>";
            str += "</form>";

            var form = $(str);

            $('body').append(form);
            $(form).submit();
        },
    },
}
</script>

<template>
    <div>
        <section class="title-section border">
            <div class="container sm">
                <h1>{{ trans("Bestilling") }}</h1>
                <a :href="this.app.startPage" class="btn secondary b-shadow">{{ trans("Shop videre") }}</a>
            </div>
        </section>
        <section class="progress-bar border">
            <div class="container sm">
                <!--                todo: It should be displayed only for orders > 499kr. Fix it-->
                <!--                <span class="progress-info">{{ trans("Du har opnået gratis levering til døren. Godt gået!") }}</span>-->
                <progress max="100" value="100"></progress>
            </div>
        </section>
        <section class="biiling-section">
            <div class="container sm">
                <div class="billing-holder b-shadow">
                    <div class="header-block border">
                        <h2>{{ trans("Dine oplysninger") }}</h2>
                        <span class="subtitle">{{ trans("Fakturering & leveringsoplysninger") }}</span>
                    </div>
                    <div class="billing-form">
                        <form action="#">
                            <div class="row-holder">
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="5" type="text" name="firstname" v-model="this.firstname" class="{'input-empty': inputErrors.firstname}">
                                        <label for="5" class="form-label">{{ trans("Fornavn") }}</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="6" type="text" name="lastname" v-model="this.lastname" class="{'input-empty': inputErrors.lastname}">
                                        <label for="6" class="form-label">{{ trans("Efternavn") }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row-holder">
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="7" type="text" name="email" v-model="this.email" class="{'input-empty': inputErrors.email}">
                                        <label for="7" class="form-label">{{ trans("E-mail") }}</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="8" type="text" name="phone" v-model="this.phone" class="{'input-empty': inputErrors.phone}">
                                        <label for="8" class="form-label">{{ trans("Telefon") }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row-holder">
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="9" type="text" name="address" v-model="this.address" class="{'input-empty': inputErrors.address}">
                                        <label for="9" class="form-label">{{ trans("Adresse") }}</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row-holder">
                                        <div class="w-25">
                                            <div class="input-holder">
                                                <input id="10" type="text" name="zipcode" v-model="this.zipcode" class="{'input-empty': inputErrors.zipcode}">
                                                <label for="10" class="form-label">{{ trans("Postnummer (4 cifre)") }}</label>
                                            </div>
                                        </div>
                                        <div class="w-75">
                                            <div class="input-holder">
                                                <input id="11" type="text" name="city" v-model="this.city">
                                                <label for="11" class="form-label">{{ trans("By") }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="billing-holder b-shadow">
                    <div class="header-block border">
                        <h2>{{ trans("Levering") }}</h2>
                        <span class="subtitle">{{ trans("Vælg hvordan du ønsker dit køb leveret") }}</span>
                    </div>

                    <form action="#" class="radio-form delivery-form">
                        <div class="deliveryline" v-for="deliverytype in this.app.deliverytypes">
                            <span class="radiobtn">
                                <input name="deliverytype" type="radio" v-model="this.selectedDelivery"  :id="deliverytype.id" :value="deliverytype.id">
                                <!--                                <input type="radio" :id="deliverytype.id" name="deliverytype" :value="deliverytype.name" @click="this.setDelivery(deliverytype)">-->
                            </span>
                            <span class="labelbtn">{{ trans(deliverytype.name) }}</span>
                            <span class="deliveryprice">{{deliverytype.price}}, -</span>
                        </div>

                        <div v-if="this.selectedDelivery == this.deliverytypesConfig.siblings">
                            <div class="labelbtn">{{ trans("Jeg har allerede betalt fragt på en anden order (Indsæt token)") }}</div>
                            <div class="billing-form">
                                <div class="col-6">
                                    <div class="input-holder">
                                        <input id="sibling_token" type="text" name="sibling_token" v-model="this.sibling_token" class="{'input-empty': inputErrors.address}">
                                        <label for="sibling_token" class="form-label">{{ trans("Indsæt token") }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="footer-block">
                        <div class="info-block">
                            <i class="icon-info"></i>
                            <span class="info-text">{{ trans("Indrammede portrætter kan ikke leveres til barnets skole af hensyn til rammens sikkerhed") }}</span>
                        </div>
                    </div>
                </div>
                <div class="billing-holder b-shadow">
                    <div class="header-block border">
                        <h2>{{ trans("Betalings metode") }}</h2>
                        <span class="subtitle">{{ trans("Vælg hvordan du ønsker at betale") }}</span>
                    </div>
                    <div class="payment-methods">
                        <form class="radio-form delivery-form" action="#">
                            <div v-for="(methodName, methodId) in this.app.paymentsystems" class="input-radio">
                                <label :for="methodId">
                                    <input class="paymentsystem" name="paymentsystem" type="radio" v-model="this.selectedPayment" :id="methodId" :value="methodId">
                                    <img class="not-retina" src="/images/visa.png" alt="visa">
                                    <img class="not-retina" src="/images/mastercard.png" alt="mastercard">
                                    <img class="not-retina" src="/images/mobilepay.png" alt="image description">
                                    <img class="not-retina" src="/images/pensopay.png" alt="pensopay">
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="billing-holder b-shadow">
                    <div class="header-block check-block">
                        <div class="checkboxes">
                            <form class="checkbox-holder check">
                                <label class="switch">
                                    <input type="checkbox" v-model="isAgreeTermCond">
                                    <span class="element"></span>
                                    <p>{{ trans("Jeg har læst og accepterer " + this.app.companyName) }} <a :href="this.app.currentDomain + '/terms'">{{ trans("Handelsbetingelser") }}</a></p>
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="total-section">
            <div class="container sm">
                <div class="total-block max-499" v-if="this.app.free_shipping">
                    <h2>{{ trans("Congratulations, you have received free shipping suffer to the door!") }}</h2>
                </div>
                <div class="total-block">
                    <div class="total-text">
                        <strong class="total">{{ trans("Total") }}</strong>
                        <span class="total-slogan">{{ trans("Inkl. levering") }}</span>
                    </div>
                    <span class="total-price">{{ this.totalPrice }},-</span>
                </div>
            </div>
        </section>
        <section class="biiling-section" v-if="formError">
            <div class="container sm">
                <div class="billing-holder-error b-shadow">
                    <div class="header-block border">
                        <h2>
                            <pre style="color: red;">{{ formError }}</pre>
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <section class="biiling-section" >
            <div class="container sm">
                <div class="billing-holder-error b-shadow">
                    <div class="header-block border">
                        <h2 id="error_messages" style="color: red;">
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <section class="btn-section">
            <div class="container sm">
                <div class="btn-holder">
                    <!--                    todo: fix the link to the cart -->
                    <!--<button type="button" href="javascript:void(0)" @click="cartStep = 1" class="btn secondary b-shadow">{{ trans("Return to basket") }}</button>-->
                    <button type="button" class="btn primary b-shadow" href="javascript:void(0)" @click="submitOrder()">{{ trans("Gå til kurven") }}</button>
                </div>
            </div>
        </section>
    </div>
</template>


<style scoped>
input[type="radio"] {
    transform: scale(2);
    cursor: pointer;
}
.deliveryline{
    display: flex;
    margin: 25px 0;
}
.radiobtn{
    padding: 0px 5px;
}
.labelbtn{
    padding: 0 30px;
    color: #000000;
    font: 18px/0.9 "Helvetica Neue", "Arial", "Helvetica", sans-serif;
}
.deliveryprice{
    color: #000000;
    font: 18px/0.9 "Helvetica Neue", "Arial", "Helvetica", sans-serif;
}
.paymentsystem{
    margin-right: 30px;
}
.form-label{
    color: #979797;
}
</style>
