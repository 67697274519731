<script>
import Attribute from "./Attribute.vue";

export default {
    components: {Attribute},
    props: ['app', 'attributes', 'selectedProdId'],
    data: function () {
        return {
            selectedAttributes: [],
        }
    },
}
</script>

<template>
    <div v-for="(attribute, aId) in this.attributes">
        <Attribute :app="this.app" :attributes="this" :aId="aId" :attribute="attribute" :selectedProdId="this.selectedProdId" />
    </div>
</template>

<style scoped>

</style>
