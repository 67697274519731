<script>
export default {
    props: ['app'],
    data: function () {
        return {

        }
    },
}
</script>

<template>
    <div class="photo-service">
        <div class="container">
            <h1>{{ trans("Vælg & bestil billeder") }}</h1>
            <div class="row-holder">
                <div class="col-6">
                    <div class="text">
                        <p>{{ trans("Herunder ser du alle billederne fra fotograferingen af dit barn, derfor vil der uden tvivl være nogle med lukkede øjne og sjove grimasser. Du skal blot vælge dine yndlingsbilleder, samt hvilke formater du ønsker dem leveret i, så sørger vi for resten. Vær opmærksom på at de billeder du ser her er uredigerede. De endelige billeder du vælger bliver rettet til i lys og skygge.") }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row-holder">
                        <div class="service-block">
                            <div class="drag-holder">
                                <ul class="drag-list">
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="bg-wrapper">
                                            <img class="not-retina" src="/images/drag-img.png" alt="image description">
                                            <img class="retina" src="/images/drag-img@2x.png" alt="image description">
                                        </div>
                                    </li>
                                </ul>
                                <div class="description">
                                    <span>{{ trans("Træk dine billeder rundt, for at sammenligne dem side om side") }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="service-block">
                            <div class="img-toogle-holder">
                                <div class="image-holder">
                                    <img class="not-retina" src="/images/toogle-img.png" alt="image description">
                                    <img class="retina" src="/images/toogle-img@2x.png" alt="image description">
                                    <form class="checkbox-holder slider" action="#">
                                        <div class="toogle-holder">
                                            <label class="switch">
                                                <input type="checkbox" disabled>
                                                <span class="element"></span>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div class="description">
                                    <span>{{ trans("Vil du se hvordan dine billeder ser ud i sort/hvid? Det slår du til i topbjælken") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>

</style>
