<script>
    export default {
        props: ['app'],
        data: function () {
            return {
                bookingUrl: '',
                companyName: '',
                companyEmail: '',
                mainDomain: '',
                officialComapnyName: '',
                companyPhone: '',
                companyAddress: '',
                companyCity: '',
                companyId: '',
                logo: '',
                logoMobile: '',
            }
        },
        mounted: function() {
            this.bookingUrl = bookingUrl
            this.companyName = companyName
            this.companyEmail = companyEmail
            this.mainDomain = mainDomain
            this.officialComapnyName = officialComapnyName
            this.companyPhone = companyPhone
            this.companyAddress = companyAddress
            this.companyCity = companyCity
            this.companyId = companyId
            this.logo = logo
            this.logoMobile = logoMobile
        },
        methods: {
            showCartPopup: function () {
                $('#cartPopup').show(100);
            },
            closePopup: function () {
                $('body').removeClass('notScroll');
                $('body').removeClass('visual');
            },
        },
    }
</script>

<template>
    <header id="header">
        <div class="container lg">
            <div class="left-side">
                <div class="logo">
                    <a href="javascript:void(0)" @click="this.app.basketStep = 0">
                        <img :src="'/images/' + this.logoMobile" alt="" class="mobile-logo">
                        <img :src="'/images/' + this.logo" alt="" class="desktop-logo">
                    </a>
                </div>
            </div>
            <Lang/>
            <div class="right-side">
                <div class="separate-block help-block">
                    <a class="help-link" :href="this.app.currentDomain + '/faq'"></a>
                </div>
            </div>
        </div>
    </header>
</template>
