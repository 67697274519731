<script>
export default {
    props: ['app'],
    data: function () {
        return {

        }
    },
    methods: {
        showCartPopup: function () {
            $('#cartPopup').show(100);
        },
        closePopup: function () {
            $('body').removeClass('notScroll');
            $('body').removeClass('visual');
        },
    },
}
</script>

<template>
    <header id="header">
        <div class="container lg">
            <div class="left-side">
                <div class="logo">
                    <a href="javascript:void(0)" @click="this.app.basketStep = 0">
                        <img :src="'/images/' + this.app.logoMobile" alt="" class="mobile-logo">
                        <img :src="'/images/' + this.app.logo" alt="" class="desktop-logo">
                    </a>
                </div>
                <ul class="breadcrumbs">
                    <li><a href="javascript:void(0)">{{ this.app.firstname }}</a></li>
                    <li><a href="javascript:void(0)">{{ this.app.className }}</a></li>
                    <li :class="{'active': this.app.basketStep == 0}"><a href="javascript:void(0)" @click="this.app.basketStep = 0">{{ trans("Billeder") }}</a></li>
                    <li><a href="javascript:void(0)">{{ this.app.customerId }}</a></li>
                    <li class="active" v-if="this.app.basketStep > 0"><a href="javascript:void(0)">#{{ this.app.basket.image.number }}</a></li>
                </ul>
            </div>
            <Lang/>
            <div class="right-side">
                <form class="separate-block checkbox-holder slider" v-if="this.app.basketStep == 0">
                    <div class="toogle-holder">
                        <label class="switch">
                            <input type="checkbox" @change="this.app.isGreyImg=!this.app.isGreyImg">
                            <span class="element"></span>
                        </label>
                        <span class="sort-name">{{ trans("Vis i sort/hvid") }}</span>
                    </div>
                </form>

                <div class="total-price">
                    <span class="total">{{ trans("Total") }}</span>
                    <span class="price">{{ this.app.basket.total }},-</span>
                    <a v-if="this.app.basketStep > 3" href="javascript:void(0)" @click="this.app.addToOrder()" class="btn primary">{{ trans("Tilføj til kurv") }}</a>
                </div>
                <div class="separate-block help-block">
                    <a class="help-link" :href="this.app.currentDomain + '/faq'"></a>
                </div>
                <div class="separate-block store-block">
                    <a href="javascript:void(0)" @click="this.showCartPopup()" class="store-link"></a>
                    <div class="store-popup">
                        <div class="store-info">
                            <h2>{{ trans("Velkommen!") }}</h2>
                            <div class="text">
                                <p><i>{{ trans("Har du bestilt på tilmeldingssedlen ligger det klar i din kurv.\nGå blot ind og vælg billeder.\nØnsker du at ændre bestilling fjerner du bestillingen og laver en ny.\nEr der ingen bestilling i din kurv laver du blot en.Husk at færdiggøre din bestilling ved at gå til betaling, da uafsluttede ordre slettes ved deadline.") }}</i></p>
                            </div>
                            <div class="btn-holder">
                                <!--                                <a href="javascript:void(0)" @click="this.closePopup()" class="btn primary">{{ trans("Fjern opret ny bestilling") }}</a>-->
                                <!--                                <a href="javascript:void(0)" @click="this.closePopup()" class="btn secondary">{{ trans("Opret ny bestilling") }}</a>-->
                                <a href="javascript:void(0)" @click="this.closePopup()" class="btn primary">{{ trans("Gå videre til bestilling") }}</a>
                            </div>
                        </div>
                    </div>
                    <span class="count-item count-item-cart">{{ this.app.cartQnt }}</span>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>

</style>
