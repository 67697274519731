<script>
import Header from "./Info/Header.vue"
export default {
    components: {
        Header,
    },
    data: function () {
        return {
            app: this,
            currentDomain: 'https://'+ window.location.hostname,
            panelsDomain: 'https://' + window.panelsDomain + '/',
            orderId: '',
            token: '',
        }
    },
    mounted: function () {
        this.orderId = this.$route.params.order
        this.token = this.$route.params.token
        console.log(this.orderId)
        this.getPaymentInfo()
    },
    methods: {
        getPaymentInfo: function () {
            var url = this.panelsDomain + 'api/v1/webshop',
                data = new FormData();

            data.set('action', 'getPaymentLinkInfo');
            data.set('order_id', this.orderId);
            data.set('token', this.token);

            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                console.log(response.data)
                this.paymentRequest(response.data)
            }).catch(function (error) {
                console.log(error);
            })
        },
        paymentRequest: function (data) {
            console.log(data.amount);

            var url = 'https://payment.quickpay.net',
                str = "<form method='POST' action='" + url + "'>\n";

            //todo: fix hardcode
            str += "<input type='hidden' name='version' value='" + data.version + "'></input>";
            str += "<input type='hidden' name='merchant_id' value='" + data.merchant_id + "'></input>";
            str += "<input type='hidden' name='agreement_id' value='" + data.agreement_id + "'></input>";
            str += "<input type='hidden' name='currency' value='" + data.currency + "'></input>";
            str += "<input type='hidden' name='autocapture' value='" + data.autocapture + "'></input>";
            str += "<input type='hidden' name='order_id' value='" + data.payment_id + "'></input>";
            str += "<input type='hidden' name='amount' value='" + data.amount + "'></input>";
            str += "<input type='hidden' name='continueurl' value='" + data.continueurl + "'></input>";
            str += "<input type='hidden' name='callbackurl' value='" + data.callbackurl + "'></input>";
            str += "<input type='hidden' name='cancelurl' value='" + data.cancelurl + "'></input>";
            str += "<input type='hidden' name='checksum' value='" + data.checksum + "'></input>";
            str += "</form>";

            var form = $(str);

            $('body').append(form);
            $(form).submit();
        },
    }
}
</script>

<template>
    <h1 class="blink">REDIRECTING...</h1>
</template>

<style scoped>
.blink{
    text-align: center;
    animation: blink 1s infinite;
}
@keyframes blink {
    from { opacity: 1;  }
    to { opacity: 0; }
}

</style>
