<script>
    import Header from "./Header.vue"
    export default {
        components: {
            Header,
        },
        data: function () {
            return {
                app: this,
                currentDomain: 'https://'+ window.location.hostname,
                panelsDomain: 'https://' + window.panelsDomain + '/',
                token: '',
                order_id: '',
                customer: {},
                notCustomer: false,

                bookingUrl: '',
                companyName: '',
                companyEmail: '',
                mainDomain: '',
                officialComapnyName: '',
                companyPhone: '',
                companyAddress: '',
                companyCity: '',
                companyId: '',
                logo: '',
                logoMobile: '',
            }
        },
        mounted: function () {
            this.order_id = this.$route.params.order
            this.token = this.$route.params.token

            this.getCustomer();

            this.bookingUrl = bookingUrl
            this.companyName = companyName
            this.companyEmail = companyEmail
            this.mainDomain = mainDomain
            this.officialComapnyName = officialComapnyName
            this.companyPhone = companyPhone
            this.companyAddress = companyAddress
            this.companyCity = companyCity
            this.companyId = companyId
            this.logo = logo
            this.logoMobile = logoMobile
        },
        methods: {
            showCartPopup: function () {
                $('#cartPopup').show(100);
            },
            closePopup: function () {
                $('body').removeClass('notScroll');
                $('body').removeClass('visual');
            },
            getCustomer: function () {
            var url = this.panelsDomain + 'api/v1/webshop';

            var dataObj = {
                'action': 'getCustomer',
                'order_id': this.order_id,
                'token': this.token,
            };
            this.notCustomer = true;
            var data = objectToFormData(dataObj);
            axios.post(url, data, {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                if (!response?.data?.customer) {
                    this.notCustomer = true;
                    return false;
                }
                this.notCustomer = false;
                this.customer = response.data.customer;
            }).catch(function (error) {
                console.log(error);
            })

        },
        },
    }
</script>

<template>
    <div v-if="this.notCustomer">
        <Header  :app="app" />
        <div id="main">
            <section class="title-section border">
                <div class="container sm">
                    <h1>{{ trans("Incorrect order number") }}</h1>
                </div>
            </section>
        </div>
    </div>
    <div v-else>
    <Header :app="app"/>
    <div>
        <div>
            <main id="main">
                <section class="title-section border">
                    <div class="container sm">
                        <h1>{{ trans("Hej") }} {{ this.customer.firstname + ' ' + this.customer.lastname }}</h1>
                    </div>
                </section>
                <section style="margin: 5% auto;">
                    <div class="container sm" style="font-size: 17px;">
                        <p>{{ trans("Tusind tak for din bestilling af skolefotos, det er super sejt! Vi er mega glade for at have modtaget din bestilling, og den har fået dette nummer") }} "{{ this.order_id }}".</p>
                        <p></p>
                        <p>{{ trans("Vores team er allerede i gang med at trylle og lave de fedeste billeder til dig. Vi lover at gøre dem helt fantastiske! Vi ved, at du er spændt på at få dem, og vi arbejder stenhårdt på at få dem klar til dig så hurtigt som muligt.") }}</p>
                        <p></p>
                        <p>{{ trans("Normalt tager det omkring 3-4 uger at få alle detaljerne på plads, for vi tager os tid til at gøre det rigtigt. Men tit går det faktisk hurtigere end det!") }}</p>
                        <p></p>
                        <p>
                            <span>
                                {{ trans('Hvis du har nogen spørgsmål eller bare gerne vil sludre om din ordre, så tøv ikke med at skrive til os på') }}
                                <a :href="'mailto:' + this.companyEmail">{{ this.companyEmail }}</a>
                                {{ trans('eller give os et kald på') }}
                                {{ this.companyPhone }}
                                {{ trans("Vi er her for at hjælpe dig.") }}
                            </span>
                        </p>
                        <p></p>
                        <p>{{ trans("Vi glæder os mega meget til at vise dig resultatet!") }}</p>
                        <p></p>
                        <p>{{ trans('Kan du holde på en hemmelighed? Vi fotografere også sportsklubber og erhverv, kig på ') }}<a :href='this.bookingUrl'>{{ this.bookingUrl }}</a>{{ trans(' men husk det er en hemmelighed!') }}</p>
                        <p></p>
                        <p>{{ trans("Bedste hilsner,") }}</p>
                        <p></p>
                        <p>{{ trans("Fotograferne fra " + this.companyName) }}</p>
                    </div>
                </section>
            </main>
        </div>
    </div>
    </div>
</template>
