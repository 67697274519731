<script>
export default {
    props: ['app', 'steps', 'category', 'categoryId'],
    data: function () {
        return {

        }
    },
    mounted: function () {
        if (this.app.premadeCategoryId) {
            this.displayProducts(this.app.premadeCategoryId)
        }
    },
    methods: {
        displayProducts: function (categoryId) {
            let categoryProducts = this.getCategoryProducts(categoryId)
            this.steps.categoryProducts = Object.assign({}, categoryProducts)

            this.app.basketStep = 2
            this.app.$nextTick(() => {
                document.getElementById("step2").scrollIntoView({block: 'start', behavior: 'smooth'});
            });
        },
        getCategoryProducts: function (categoryId) {
            var categoryProducts = []
            for (let [key, product] of Object.entries(this.app.products)) {
                for (let [app, category] of Object.entries(product.categories)) {
                    if (categoryId == category) {
                        categoryProducts[key] = product
                    }
                }
            }

            return categoryProducts
        },
    },
}
</script>

<template>
<!--    todo: fix hardcode-->
    <a v-if="this.categoryId !=5"  href="javascript:void(0)" @click="this.displayProducts(this.categoryId)" class="filter-block">
        <div class="image-holder">
            <img class="not-retina" :src="/images/+this.category.image" alt="image-description">
        </div>
        <strong class="filter-title">{{ trans(this.category.name) }}</strong>
        <p>{{ trans(this.category.description) }}</p>
    </a>
</template>

<style scoped>

</style>
