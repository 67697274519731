<script>


export default {
    props: ['app', 'addon'],
    data: function () {
        return {
            qtyOnStep: 1,
            selectedAddonId: null,
            currentQty: 0,


            selectedAttributes: [],
            product: this.app.products[this.selectedProdId],
            selectedVal: null,

            attributes: null,
            selectedProdId:null,

        }
    },
    mounted() {
        this.attributes = this.app.products[this.addon.id].attributes
        this.selectedProdId = this.selectedAddonId

        this.setValues()
        if (this.addon.defquantity) {
            this.qtyOnStep = this.addon.defquantity
        }
    },
    methods: {
        updateQty: function (action) {
            if (!this.app.basket.products[this.addon.id]) {
                let product = {
                    id: this.addon.id,
                    price: this.app.products[this.addon.id].price,
                }
                this.app.addToBasket(product, 0)
                this.selectedAddonId = this.addon.id
            }

            this.currentQty = this.app.updateBasketQty(this.addon.id, action, this.qtyOnStep, this.addon.maxquantity)

            if (this.currentQty > 0) {
                this.product = this.app.products[this.selectedProdId]
                this.selectedVal = null
                this.selectedAttributes = []
                this.setValues()
            }

        },





        getDefValues: function (aValues) {
            let defValues = []
            for (let [attributeValueId, attributeValue] of Object.entries(aValues)) {
                if (attributeValue.default == 1) {
                    defValues.push(attributeValueId)
                }
            }

            return defValues
        },
        getSelectedValues: function (aId) {
            let selectedValues = []
            if (this.app.basket.products[this.app.prodId].selectedAttributes
                && this.app.basket.products[this.app.prodId].selectedAttributes[aId]) {
                selectedValues = this.app.basket.products[this.app.prodId].selectedAttributes[aId]
            }

            return selectedValues
        },
        getValues: function (aId, aValues) {
            let values = []
            let selectedValues = this.getSelectedValues(aId)
            let defValues = this.getDefValues(aValues)

            values = selectedValues
            if (defValues.length > 0) {
                values = defValues
            }

            return values
        },
        setValues: function () {
            if ( this.selectedProdId === null) {
                return
            }

            for (let [aId, aValues] of Object.entries(this.attributes)) {
                let values = this.getValues(aId, aValues)
                for (let [key, valueId] of Object.entries(values)) {
                    this.addValue(aId, valueId)
                }
            }

            this.app.addToBasket(this.product, this.currentQty, this.selectedAttributes)
        },
        addValue: function (aId, valueId) {
            if (!this.selectedAttributes[aId]) {
                this.selectedAttributes[aId] = []
            }
            //todo: #271 - I think problem might be here when aId or valueId is missed
            this.selectedAttributes[aId].push(valueId)
            return this.selectedAttributes
        },
    }
}
</script>

<template>
    <div class="product_item">
        <div class="product_name">{{ trans(this.addon.name) }}</div>
        <div class="adding-box">
            <div class="prod_price">{{ this.app.products[this.addon.id].price }}, -</div>
            <div  v-if="!this.app.basket.products[this.addon.id] || this.app.basket.products[this.addon.id].qty==0" class="add_button btn primary" @click="this.updateQty('plus')"><span>{{ trans("Tilføj") }}</span></div>
            <div v-else class="product_count">
                <div class="input_holder">
                    <span class="minus btn-minus" href="javascript:void(0)" @click="this.updateQty('minus')"><i class="icon-minus"></i></span>
                    <input class="counter" :readonly="true" v-model="this.app.basket.products[this.addon.id].qty">
                    <span class="plus btn-plus" href="javascript:void(0)" @click="this.updateQty('plus')"><i class="icon-plus"></i></span>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

.product_item{
    display: flex;
    font-family: sans-serif;
    font-size: 16px;
    justify-content: space-between;
    color: #000;
    background-color: #fff;
    padding: 10px;
    align-items: center;
}
.product_item div {
    display: flex;
}
.product_name {
    font-weight: 600;
}
.prod_price {
    margin-right: 10px;
}
.adding-box{
    align-items: center;
}
.add_button {
    margin-bottom: 0;
    min-width: 70px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
}
.add_button:hover {
    cursor: pointer;
}
.product_count{
    color: #000;
    border: 1px solid #000;
}
.input_holder{
    position: relative;
}
.minus{
    font-size: 2px;
    text-decoration: none;
    color: #000;
    width: 20px;
    padding: 12px 10px;
}
.plus{
    font-size: 8px;
    text-decoration: none;
    color: #000;
    width: 20px;
    padding: 8px 4px;
}
.counter{
    border: 0px;
    width: 28px;
    text-align: center;
    font-size: 12px;
    color: #000;
}

</style>
