<script>
    export default {
        props: ['app', 'isPhotos'],
        data: function () {
            return {
                panelsDomain: 'https://' + window.panelsDomain + '/',
                currentDomain: 'https://' + window.location.hostname,

                bookingUrl: '',
                companyName: '',
                companyEmail: '',
                mainDomain: '',
                officialComapnyName: '',
                companyPhone: '',
                companyAddress: '',
                companyCity: '',
                companyId: '',
                logo: '',
                logoMobile: '',
            }
        },
        mounted: function () {
            this.id = this.$route.params.customer
            this.token = this.$route.params.token

            this.bookingUrl = bookingUrl
            this.companyName = companyName
            this.companyEmail = companyEmail
            this.mainDomain = mainDomain
            this.officialComapnyName = officialComapnyName
            this.companyPhone = companyPhone
            this.companyAddress = companyAddress
            this.companyCity = companyCity
            this.companyId = companyId
            this.logo = logo
            this.logoMobile = logoMobile
        },
        methods: {
            getZipPhotosToDownload: function () {
                var url = this.panelsDomain + 'api/v1/webshop',
                        data = new FormData();

                data.set('action', 'getZipPhotosToDownload');
                data.set('id', this.id);
                data.set('token', this.token);

                axios.post(url, data, {
                    headers: {
                        'Accept': 'application/json',
                    }
                }).then(response => {

                    if (!response.data.url) {
                        return false;
                    }
                    window.location = this.panelsDomain + response.data.url;

                }).catch(function (error) {
                    console.log(error);
                })
            },
        },
    }
</script>

<template>
    <header id="header">
        <div class="container lg">
            <div class="left-side">
                <div class="logo">
                    <a href="javascript:void(0)" @click="this.app.basketStep = 0">
                        <img :src="'/images/' + this.logoMobile" alt="" class="mobile-logo">
                        <img :src="'/images/' + this.logo" alt="" class="desktop-logo">
                    </a>
                </div>
            </div>
            <Lang/>
            <div class="right-side">
                <!--<div class="btn-block separate-block" style="display: block;" id="downloadAll" v-if="this.app.isPhotos">
                    <button @click="getZipPhotosToDownload" download="" class="btn btn-dark btn-lg">Download alle</button>
                </div>
                <div class="btn-block separate-block" style="display: block;" id="downloadAll">
                    <a href="" download="" class="btn btn-dark btn-lg">Bestil mere</a>
                </div>-->
                <div class="separate-block help-block">
                    <a class="help-link" :href="this.app.currentDomain + '/faq'"></a>
                </div>
            </div>
        </div>
    </header>
</template>
