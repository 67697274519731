<script>

export default {
    name: 'Lang',
    data: function () {
        return {
        }
    },
    mounted: function () {
        this.lang = (!this.getCookie('language')) ? 'dk' : this.getCookie('language');
    },
    methods: {
        switchLanguage: function (language) {
            this.setcookie('language', language, 30, '/');
            location.reload();

        },
    },
};
</script>

<template>
    <div class="right-side dropdown">
        <a href="javascript:void(0)" class="dropbtn" v-if="lang == 'dk'">
            <img src="/images/danish.png" alt="" class="mobile-logo flag">
            <i class="material-icons" style="font-size:30px; color:grey">arrow_drop_down</i>
        </a>
        <a href="javascript:void(0)" class="dropbtn" v-if="lang == 'en'">
            <img src="/images/english.png" alt="" class="mobile-logo flag">
            <i class="material-icons" style="font-size:30px; color:grey">arrow_drop_down</i>
        </a>
        <div class="dropdown-content">
            <a href="javascript:void(0)" @click="switchLanguage('en')" v-if="lang == 'dk'">
                <img src="/images/english.png" alt="" class="mobile-logo flag">
            </a>
            <a href="javascript:void(0)" @click="switchLanguage('dk')" v-if="lang == 'en'">
                <img src="/images/danish.png" alt="" class="mobile-logo flag">
            </a>
        </div>
    </div>
</template>
