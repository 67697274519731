<script>
export default {
    props: ['app', 'steps', 'product'],
    data: function () {
        return {
            attributeId: null,
            attributeName: null,
            attributeValues: {}
        }
    },
    mounted() {
        if (this.app.premadeProductId) {
            this.displayStep3(this.app.premadeProductId)
        }
    },
    methods: {
        displayStep3: function(productId) {
            this.app.resetBasketProducts()
            this.app.prodId = productId
            this.app.basketStep = 3
            this.app.$nextTick(() => {
                document.getElementById("step3").scrollIntoView({block: 'start', behavior: 'smooth'});
            });
        },
    },
}
</script>

<template>
    <div @click="this.displayStep3(this.product.id)" class="filter-block b-shadow" >
        <div class="filter-title">{{ trans(this.product.name) }}</div>
        <div class="prod_descr" v-html="trans(this.product.description)"></div>
        <div class="total">{{ this.product.price }},-</div>
    </div>
</template>

<style scoped>
.filter-block {
    padding-top: 26px;
    height: 360px;
}
.filter-block:hover {
    cursor: pointer;
}
.b-shadow {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 4%);
}
.prod_descr{
    flex: auto;
    align-items:stretch;
    align-content: stretch;
}
</style>
