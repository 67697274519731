<script>
    import Header from "./Header.vue"
    export default {
        components: {
            Header,
        },
        data: function () {
            return {
                app: this,
                currentDomain: 'https://'+ window.location.hostname,
            }
        },
        methods: {
            showCartPopup: function () {
                $('#cartPopup').show(100);
            },
            closePopup: function () {
                $('body').removeClass('notScroll');
                $('body').removeClass('visual');
            },
        },
    }
</script>

<template>
    <Header :app="app"/>
    <div>
        <div>
            <main id="main">
                <section class="title-section border">
                    <div class="container sm">
                        <h1>{{ trans("Cancel") }}</h1>
                    </div>
                </section>
                <section class="terms-conditions">
                    <div class="container sm">
                        <p>{{ trans("Not FOUND") }}</p>
                    </div>
                </section>
            </main>
        </div>
    </div>

</template>
